// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import uniqueId from "lodash.uniqueid";
import { CartGallery } from "../../../Library/PageDevices/CartGallery/CartGallery";
import parse from "html-react-parser";
import { TopToursDetailsContext } from "./TopToursDetails";

export const TopToursDetailsDiv = ({ className }) => {
  const { width, ttDetails } = useContext(TopToursDetailsContext);
  return (
    <div
      className={className}
      style={{ width: `${width * 0.9}px` }}
      data-component="TopToursDetailsDiv"
    >
      {
        // ##### 24-03-21 Komarov
        ttDetails?.map((item) => {
          if (item.content_name === "Image") {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>
                <h2
                  style={{
                    color: "#102D69",
                    fontFamily: "Arial",
                    fontSize: "30px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "4vh"
                  }}
                >
                  {item.contract_name}
                </h2>

                <div>
                  <CartGallery
                    photos={item}
                    // photoHeight={"60vh"}
                    // photoWidth={'80%'}
                    smallImageHeight={"15vh"}
                  />
                </div>
              </div>
            );
          }

          // ##### 24-03-21 Komarov
          if (item.content_name === "Body") {
            return (
              // ##### 23-04-12 Komarov
              <div key={uniqueId()}>{parse(item.text)}</div>
            );
          }
        })
      }
    </div>
  );
};