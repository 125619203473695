// ##### 25-02-05 Komarov
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import React from "react";

export const AllHotelsList = ({ style = null/* , hotels_overview_all_data, selected_currency, start, end */ }) => {
  const hotels_overview_all_data = useSelector(state => state.hotels.hotels_overview_all[0].data.object_list);
  return (
    <ul style={style}>
      {hotels_overview_all_data.map(hotel => {
        return <li key={uniqueId()}>
          <LocalizationNavLink
            // ##### 24-08-23 Komarov
            /* to={`/hotel_details/?selected_currency=${selected_currency}&start=${start}&end=${end}&id=${hotel.object_id}&city_id=${hotel.city_id}&adults=1&children=0&rooms=1&hotel_name=${hotel.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${hotel.object_id}`} */
            to={`/hotel_overview/?id=${hotel.object_id}&city_id=${hotel.city_id}&hotel_name=${hotel.object_name.toLowerCase().replace(/\s/g, "-").toLowerCase()}&hotel_id=${hotel.object_id}`}
          >
            {/* ##### 24-07-24 Komarov */}
            {hotel.object_langname}
          </LocalizationNavLink>
        </li>;
      })}
    </ul>
  );
};