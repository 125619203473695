// ##### 24-09-09 Komarov
import React, { useState } from "react";
import { IconHint } from "./iconHint";
// ##### 23-10-31 Komarov
import { Media } from "../../../helpers/MediaConfig";
// ##### 23-10-10 Komarov
const SvgTag = ({ 
  style = null, 
  className = '',
  setIsHintShown, 
  setY
}) => {
  // ##### 24-08-16 Komarov
  const onMouseEnter = (e) => {
    setIsHintShown(true);
    setY(e.clientY + 5);
  };

  // ##### 24-08-16 Komarov
  const onMouseLeave = () => {
    setIsHintShown(false);
    setY(undefined);
  };

  return (
    <svg
      // ##### 23-03-23 Komarov
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="user"
      // ##### 23-10-31 Komarov
      className={`svg-inline--fa fa-user fa-w-14 ${className}`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      marginright="-1vw"
    /* style={{marginRight: '-1vw'}} */
    >
      <path
        fill="currentColor"
        d="M20 24H4c-2.2 0-4-1.8-4-4V5c0-.6.4-1 1-1h22c.6 0 1 .4 1 1v15c0 2.2-1.8 4-4 4zM2 6v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6H2z"
      />
      <path
        fill="currentColor"
        d="M23 5H1V4c0-1.7 1.3-3 3-3h16c1.7 0 3 1.3 3 3v1z"
      />
      <path
        fill="currentColor"
        d="M23 6H1c-.6 0-1-.4-1-1V4c0-2.2 1.8-4 4-4h16c2.2 0 4 1.8 4 4v1c0 .6-.4 1-1 1zM2 4h20c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2z"
      />
      <path
        fill="currentColor"
        d="M7 24c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1s1 .4 1 1v18c0 .6-.4 1-1 1z"
      />
      <path
        fill="currentColor"
        d="M23 12H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1zM23 18H1c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1z"
      />
    </svg>
  );
};

export const Operations = ({ hintText }) => {
  const [isHintShown, setIsHintShown] = useState(false);
  const [y, setY] = useState(undefined);

  return (
    <div
      data-component="Operations"
      style={{
        position: "relative",
        zIndex: 2001,
      }}
    >
      {/* ##### 23-10-31 Komarov */}
      <Media lessThan="Laptop-1024-1440">
        <SvgTag
          style={{ width: "7.5vw" }}
          className='OperationsMobile'
          setIsHintShown={setIsHintShown}
          setY={setY}
        />
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SvgTag
          style={{ width: "2.2vw" }}
          setIsHintShown={setIsHintShown}
          setY={setY}
        />
      </Media>
      <IconHint isHintShown={isHintShown} hintText={hintText} y={y} />
    </div>
  );
};
