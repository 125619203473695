import {
  GET_GENERAL_HOTELS_ERR,
  GET_GENERAL_HOTELS_REQ,
  GET_GENERAL_HOTELS_RES,
  GET_HOTEL_NAME_ERR,
  GET_HOTEL_NAME_REQ,
  GET_HOTEL_NAME_RES,
  GET_HOTELS_ERR,
  GET_HOTELS_OVERVIEW_ALL_ERR,
  GET_HOTELS_OVERVIEW_ALL_REQ,
  GET_HOTELS_OVERVIEW_ALL_RES,
  GET_HOTELS_OVERVIEW_TOP_ERR,
  GET_HOTELS_OVERVIEW_TOP_REQ,
  GET_HOTELS_OVERVIEW_TOP_RES,
  GET_HOTELS_REQ,
  GET_HOTELS_RES,
  GET_HOTELSTRANS_ERR,
  GET_HOTELSTRANS_REQ,
  GET_HOTELSTRANS_RES,
  // ##### 25-02-12 Komarov
  GET_HOTELS_AVAIL_REQ,
  GET_HOTELS_AVAIL_RES,
  GET_HOTELS_AVAIL_ERR
} from "../constants";

const initState = {
  hotels: [],
  hotels_loaded: false,
  hotels_errors: [],

  // ##### 25-02-12 Komarov
  hotels_avail: [],
  hotels_avail_loaded: false,
  hotels_avail_errors: [],

  hotelstrans: [],
  hotelstrans_loaded: false,
  hotelstrans_errors: [],

  general_hotels: [],
  general_hotels_loaded: false,
  general_hotels_errors: [],

  hotel_name: "",
  hotel_name_loaded: false,
  hotel_name_errors: [],

  // ##### 24-07-31 Komarov
  hotels_overview_top: [],
  hotels_overview_top_loaded: false,

  // ##### 24-07-31 Komarov
  hotels_overview_all: [],
  hotels_overview_all_loaded: false,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    // ##### 24-07-31 Komarov
    case GET_HOTELS_OVERVIEW_ALL_REQ:
      return {
        ...state,
        hotels_overview_all_loaded: false,
      };

    case GET_HOTELS_OVERVIEW_ALL_RES:
      return {
        ...state,
        hotels_overview_all_loaded: true,
        hotels_overview_all: action.payload,
      };

    case GET_HOTELS_OVERVIEW_ALL_ERR:
      return {
        ...state,
        hotels_overview_all_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 24-07-31 Komarov
    case GET_HOTELS_OVERVIEW_TOP_REQ:
      return {
        ...state,
        hotels_overview_top_loaded: false,
      };

    case GET_HOTELS_OVERVIEW_TOP_RES:
      return {
        ...state,
        hotels_overview_top_loaded: true,
        hotels_overview_top: action.payload,
      };

    case GET_HOTELS_OVERVIEW_TOP_ERR:
      return {
        ...state,
        hotels_overview_top_loaded: false,
        errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_HOTELS_REQ:
      return {
        ...state,
        hotels_loaded: false,
      };

    case GET_HOTELS_RES:
      return {
        ...state,
        hotels_loaded: true,
        hotels: action.payload,
      };

    case GET_HOTELS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotels_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    // ##### 25-02-12 Komarov
    case GET_HOTELS_AVAIL_REQ:
      return {
        ...state,
        hotels_avail_loaded: false,
      };

    case GET_HOTELS_AVAIL_RES:
      return {
        ...state,
        hotels_avail_loaded: true,
        hotels_avail: action.payload,
      };

    case GET_HOTELS_AVAIL_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotels_avail_errors: (state.errors && [...state.errors, action.error]) || [
          action.error,
        ],
      };

    case GET_GENERAL_HOTELS_REQ:
      return {
        ...state,
        general_hotels_loaded: false,
      };

    case GET_GENERAL_HOTELS_RES:
      return {
        ...state,
        general_hotels_loaded: true,
        general_hotels: action.payload,
      };

    case GET_GENERAL_HOTELS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        general_hotels_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    case GET_HOTEL_NAME_REQ:
      return {
        ...state,
        hotel_name_loaded: false,
      };

    case GET_HOTEL_NAME_RES:
      return {
        ...state,
        hotel_name_loaded: true,
        hotel_name: action.payload,
      };

    case GET_HOTEL_NAME_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotel_name_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    case GET_HOTELSTRANS_REQ:
      return {
        ...state,
        hotelstrans_loaded: false,
      };

    case GET_HOTELSTRANS_RES:
      return {
        ...state,
        hotelstrans_loaded: true,
        hotelstrans: action.payload,
      };

    case GET_HOTELSTRANS_ERR:
      return {
        ...state,
        //    ##### 23-09-01 Komarov
        hotelstrans_errors: (state.errors && [
          ...state.errors,
          action.error,
        ]) || [action.error],
      };

    default:
      return state;
  }
};

export default reducer;

// import { GET_DESC_REQ, GET_DESC_RES , GET_DESC_ERR,GET_EN_DESC_REQ, GET_EN_DESC_RES , GET_EN_DESC_ERR} from '../constants';
// import { GET_IMAGES_REQ, GET_IMAGES_RES , GET_IMAGES_ERR} from '../constants'

// const initState = {
// 	parts: [],
// 	loaded: false,
// 	errors: [],

//     en_parts: [],
//     en_parts_loaded: false,
//     errors: [],

//     images: [],
//     images_loaded: false,
//     errors: []
// }

// const reducer = ( state = initState, action ) => {
// 	switch( action.type ){

// 		case GET_DESC_REQ:
// 			return({
// 				...state,
// 				loaded: false
// 			})

// 		case GET_DESC_RES:
// 			return({
// 				...state,
// 				loaded: true,
// 				parts: action.payload
// 			})

// 		// case 'REMOVE_DESC':
// 		// 	return({
//         //         ...state,
//         //         htls: state.htls.filter( htl => parseInt(htl.id) !== parseInt(action.payload) )
// 		// 	})

// 		case GET_DESC_ERR:
// 			return({
// 				...state,
// 				errors: [...state.errors, action.error ]
// 			})

//             case GET_EN_DESC_REQ:
// 			return({
// 				...state,
// 				en_parts_loaded: false
// 			})

// 		case GET_EN_DESC_RES:
// 			return({
// 				...state,
// 				en_parts_loaded: true,
// 				en_parts: action.payload
//             })

//             case GET_EN_DESC_ERR:
//                 return({
//                     ...state,
//                     errors: [...state.errors, action.error ]
//                 })

//                 case GET_DESC_ERR:
// 			return({
// 				...state,
// 				errors: [...state.errors, action.error ]
// 			})

//         case GET_IMAGES_REQ:
// 			return({
// 				...state,
// 				images_loaded: false
// 			})

// 		case GET_IMAGES_RES:
// 			return({
// 				...state,
// 				images_loaded: true,
// 				images: action.payload
//             })

//             case GET_IMAGES_ERR:
//                 return({
//                     ...state,
//                     errors: [...state.errors, action.error ]
//                 })

//         default:
// 			return state;
//     }

// }

// export default reducer;
