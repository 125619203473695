// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../../../helpers/messages";
import { Input } from "antd";
import uniqueId from "lodash.uniqueid";
import React from "react";

export const PackageTourComponent = ({ props }) => {
  const {
    maxLength,
    AddPackageTourComments,
    packageTourComments
  } = props;
  const packageTourCommentsMessage = useMessages("Package Tour");
  // ##### 23-10-17 Komarov
  const { TextArea } = Input;
  return (<div key={uniqueId()} className="form-block-lf">
      <span>{packageTourCommentsMessage}</span>
      <TextArea
        rows={1}
        maxLength={maxLength}
        style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}
        onChange={AddPackageTourComments}
        value={packageTourComments}
      />
    </div>
  );
};