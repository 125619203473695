// ##### 25-02-05 Komarov
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ValidateQuery } from "../../Helpers/helper";
import { Select } from "antd";
import { getPickedCurrencyResponse } from "../../../../Redux/actions";
import { CurrencySelectionToolContext } from "./CurrencySelectionTool";
import { OptionContent } from "./OptionContent";

export const CurrencySelect = ({ dropdownStyle = null }) => {
  // ##### 24-05-10 Komarov
  const { path } = useContext(CurrencySelectionToolContext);
  const deviceType = useSelector((state) => state.deviceType);

  // ##### 24-02-22 Komarov
  const location = useLocation();
  // ##### 25-01-15 Komarov
  // const search_data = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const search_data = ValidateQuery(location, shouldCypherUrlParams);

  // ##### 22-08-29 Komarov: Добавил значение валюты по умолчанию, если оно отсутствует в path.selected_currency
  // ##### 24-02-22 Komarov
  const [currency, setCurrency] = useState(
    search_data.selected_currency || path.selected_currency || "UAH"
  );
  const { Option } = Select;
  // ##### 24-02-22 Komarov
  useEffect(() => {
    localStorage.setItem("current_currency", currency);
    // ##### 24-07-30 Komarov
    dispatch(getPickedCurrencyResponse(currency));
  }, [currency]);

  // const pickedCurrency = useSelector(state=>state.currency.pickedCurrency)
  // const currencies = useSelector(state => state.currency.currencies)
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector(state => state.currency.currencies);
  const currencies = (currenciesFromState.length > 0 && currenciesFromState)
    || JSON.parse(localStorage.getItem("currency")).currencies;

  // ##### 24-02-22 Komarov
  const dispatch = useDispatch();
  const history = useHistory();

  // ##### 23-02-14 Komarov
  const SelectCurrency = (value) => {
    setCurrency(value);
    // ##### 24-02-21 Komarov
    // history.push(`/${locale}/?selected_currency=${value}`)
    history.push(
      `${location.pathname}${location.search.replace(
        search_data.selected_currency,
        value
      )}`
    );
    // ##### 24-02-22 Komarov
    localStorage.setItem("current_currency", value);
    dispatch(getPickedCurrencyResponse(value));
  };

  return (
    <Select
      defaultValue={currency}
      dropdownStyle={dropdownStyle}
      onChange={SelectCurrency}
      bordered={true}
      // ##### 24-03-21 Komarov
      size={deviceType !== "tablet" ? "small" : "middle"}
    >
      <>
        {currencies?.map((item) => {
          // ##### 22-11-14 Komarov
          return (
            <Option value={item.name} key={item.id}>
              <OptionContent item={item} />
            </Option>
          );
        })}
      </>
    </Select>
  );
};