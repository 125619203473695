// ##### 25-02-05 Komarov
import React, { useRef } from "react";
import { ImageShown } from "./ImageShown";
import uniqueId from "lodash.uniqueid";
import AsynchronousImage from "./AsynchronousImage";

export const CartGalleryDiv = ({
  height,
  flexDirection,
  width1,
  width2,
  photos,
  smallImageHeight
}) => {
  // ##### 24-11-29 Komarov
  const shownImgRef = useRef({ src: undefined });
  const imgPreviews = useRef();
  let currentPreviewIndex = undefined;

  const pickAction = (index) => (e) => {
    shownImgRef.current.src = e.target.getAttribute("src");
    const imagePreviews = imgPreviews.current.children;
    if (currentPreviewIndex || currentPreviewIndex === 0) {
      const prevChosenImage = imagePreviews[currentPreviewIndex].firstChild.firstChild;
      prevChosenImage.classList.remove("CartPickedImage");
      prevChosenImage.classList.add("CartNotPickedImage");
    }
    currentPreviewIndex = index;
    const chosenImage = imagePreviews[index].firstChild.firstChild;
    chosenImage.classList.remove("CartNotPickedImage");
    chosenImage.classList.add("CartPickedImage");
  };

  // ##### 23-11-08 Komarov
  return (
    <div
      data-component="CartGalleryDiv"
      style={{
        width: "96%",
        height,
        display: "flex",
        flexDirection
      }}
    >
      <>
        {/*##### 24-11-29 Komarov*/}
        <ImageShown
          width1={width1}
          shownImgRef={shownImgRef}
          firstPhotoUrl={(process.env.REACT_APP_PROTOCOL || "https://") + photos.text[0]}
        />

        <div
          ref={imgPreviews}
          style={{
            // ##### 24-06-26 Komarov
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            //  flexDirection:'row',
            //  justifyContent:'space-evenly'
            // display: "grid",
            // gridTemplateColumns: "repeat(4,25%)",
            // rowGap: "0",
            // ##### 22-09-05 Komarov
            width: width2,
            // overflow: "scroll",
            overflowY: "auto"
            //  justifyContent:'space-evenly'
          }}
        >
          {photos.text.map((image, index) => {
            return (
              <div key={uniqueId()} className="galleryPage">
                <AsynchronousImage
                  src={(process.env.REACT_APP_PROTOCOL || "https://") + image}
                  alt="Gallery element"
                  style={{
                    height: `${smallImageHeight ? smallImageHeight : null}`
                  }}
                  onClick={pickAction(index)}
                  // ##### 24-03-21 Komarov
                  onKeyUp={(e) => {
                    if (e.key === "Enter") pickAction(index);
                  }} // Example of adding keyboard accessibility
                />
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};