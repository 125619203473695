// ##### 24-09-09 Komarov
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// ##### 24-03-01 Komarov
// ##### 25-02-05 Komarov
import { changeLang, getCurrency, getPickedCurrencyResponse } from "../../../../Redux/actions";
import { ValidateQuery } from "../../Helpers/helper";
// ##### 23-09-21 Komarov
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 24-04-23 Komarov
import parse from "html-react-parser";
// ##### 25-02-05 Komarov
import { HeaderWrapperMain } from "./HeaderWrapperMain";

// ##### 25-02-05 Komarov
export const HeaderWhiteLabelContext = createContext();

export const HeaderWhiteLabel = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ##### 23-09-21 Komarov
  const toggler_type = useSelector((state) => state.tophotels.toggler_type);
  // ##### 24-04-23 Komarov
  const body_messages = Object.values(useMessages()).filter(
    (item) => parseInt(item.sitepage_region_id) === 13
  );

  let path;
  const location = useLocation();
  // ##### 25-01-15 Komarov
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  if (location.search) {
    // path = ValidateQuery(location);
    path = ValidateQuery(location, shouldCypherUrlParams);
  } else path = { selected_currency: "UAH", default: true };

  const dispatch = useDispatch();
  dispatch(getPickedCurrencyResponse(path.selected_currency));

  const lang = useSelector((state) => state.language);
  // ##### 24-07-25 Komarov
  const currencies = useSelector((state) => state.currency.currencies);

  // Думал, что язык работает и без этого запроса
  useEffect(() => {
    dispatch(changeLang(lang));
    // ##### 23-04-12 Komarov
  }, [lang, dispatch]);
  // Запрашиваем валюту из Смарта для сайта
  useEffect(() => {
    // ##### 24-07-25 Komarov
    (process.env.NODE_ENV === "development" &&
      currencies.length > 0) ||
      console.log(
        "axios:src/Components/Pages/PageComponents/ResponsiveHeader/HeaderWhiteLabel.js:getCurrency()"
      );
    currencies.length > 0 ||
      dispatch(getCurrency());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhitelabelSet = whitelabel.set;
  const imgTag = isWhitelabelSet && whitelabel.data[lang].Image;
  const img = isWhitelabelSet && parse(imgTag);
  const backgroundImage = isWhitelabelSet && img.props.src;
  const currentUrl = useSelector((state) => state.currentUrl);
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-08-05 Komarov
  const isMainPage = isWhitelabelSet &&
    company_cipher.length + currentUrl.indexOf(company_cipher) ===
    currentUrl.length;

  // ##### 25-01-22 Komarov
  const headerWhiteLabelContextValue = useMemo(() => ({
    // ##### 24-08-05 Komarov
    backGroundImg: backgroundImage,
    // ##### 24-02-28 Komarov
    lang,
    toggler_type,
    body_messages,
    // ##### 24-03-01 Komarov
    isMainPage,
  }), [
    lang,
    toggler_type,
    body_messages
  ]);

  return (
    <HeaderWhiteLabelContext.Provider
      value={headerWhiteLabelContextValue}
    >
      {/* ##### 24-08-05 Komarov */}
      {(isWhitelabelSet && <HeaderWrapperMain />) || <div>Loading...</div>}
    </HeaderWhiteLabelContext.Provider>
  );
};
