// ##### 25-02-05 Komarov
import React, { useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";

export const RequestsMobile = ({ pages, title, isWhiteLabelSet, companyCipher, sidebarListWidth }) => {
  const requestsRef = useRef();
  const requestsItemsRef = useRef();
  useEffect(() => {
    requestsItemsRef.current.style.right = `${sidebarListWidth}px`;
    requestsItemsRef.current.style.top = `${requestsRef.current.offsetTop}px`;
  }, [sidebarListWidth]);

  const removeRequestsItems = () => {
    const requestsItemsclassList = requestsItemsRef.current.classList;
    if (!requestsItemsclassList.contains("RequestsMobileItemsHide")) {
      requestsItemsclassList.add("RequestsMobileItemsHide");
    }
  };

  const toggleRequestsItems = (e) => {
    requestsItemsRef.current.classList.toggle("RequestsMobileItemsHide");
    e.stopPropagation();
  };

  return <OutsideClickHandler
    onOutsideClick={removeRequestsItems}>
    <a
      ref={requestsRef}
      role="button"
      data-component="Requests"
      onClick={toggleRequestsItems}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          toggleRequestsItems();
        }
      }}
    >
      <h4>{title}</h4>
    </a>
    <div
      className="RequestsMobileItemsHide"
      data-component="RequestsMobileItems"
      ref={requestsItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
            onClick={removeRequestsItems}
          >
            <h4>{parse(page.title)}</h4>
          </LocalizationNavLink>
        );
      })}
    </div>
  </OutsideClickHandler>;
};