// ##### 24-05-15 Komarov
import React, { useEffect, useRef } from "react";
// ##### 24-05-10 Komarov
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import {
  getGeneralHotels,
  getHotels,
  saveHotelsContext,
  setCounterAdultsOuter,
  setCounterChildOuter,
  setCounterRoomsOuter,
  setFormFilled,
  // ##### 25-01-22 Komarov
  setItemInfo,
  setPaxChoiceDivOpened,
  setPickedEndDate,
  setPickedHotelValue,
  // ##### 24-05-13 Komarov
  setPickedStartDate,
  setStayEndDate,
  setStayStartDate,
} from "../../../../../Redux/actions";
// ##### 23-05-08 Komarov

import config from "../../../../../Redux/config";
// ##### 23-11-02 Komarov
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 24-04-17 Komarov
import { ValidateQuery } from "../../../Helpers/helper";
// ##### 24-05-17 Komarov
import { useHistory, useLocation } from "react-router-dom";
// ##### 24-04-05 Komarov
import { makeArray } from "../../../../../../server/helpers/isArrayObject";
import { AutocompleteOuterDiv } from "./AutocompleteOuterDiv";
import { BorderInnerWrapper2DivTag } from "./BorderInnerWrapper2DivTag";
import { ClickableLayer_01_CityOrHotel } from "./ClickableLayer_01_CityOrHotel";
import { ClickableLayer_01_CityOrHotel_Alert } from "./ClickableLayer_01_CityOrHotel_Alert";
import { ClickableLayer_02_StartEndDates } from "./ClickableLayer_02_StartEndDates";
import { ClickableLayer_02_StartEndDates_Alert } from "./ClickableLayer_02_StartEndDates_Alert";
import { ClickableLayer_03_PaxChoice } from "./ClickableLayer_03_PaxChoice";
import { HotelDatesModalDiv } from "./HotelDatesModalDiv";
import { PaxChoice } from "./PaxChoice";

// ##### 24-03-15 Komarov

moment.locale(config.defaultLang);

export const HotelsAutocompleteSmallScreen = ({
  wrapper,
  formClass,
  onSubmit,
  props,
  searchProps,
}) => {
  // ##### 24-05-15 Komarov
  const cityHotelInput = useRef();
  const locale = useSelector((state) => state.language);
  const dispatch = useDispatch();
  // ##### 25-01-15 Komarov
  // const query = ValidateQuery(useLocation());
  const location = useLocation();
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const query = ValidateQuery(location, shouldCypherUrlParams);
  useEffect(() => {
    if (query?.start) {
      dispatch(setStayStartDate(query.start));
      dispatch(setPickedStartDate(true));
    }
    if (query?.end) {
      dispatch(setStayEndDate(query.end));
      dispatch(setPickedEndDate(true));
    }
  }, []);
  useEffect(() => {
    if (query?.title || searchProps) {
      dispatch(setPickedHotelValue(true));
    }
  }, []);

  const stayStartDate = useSelector(
    (state) => state.hotelsContext.stayStartDate
  );
  const stayEndDate = useSelector((state) => state.hotelsContext.stayEndDate);
  const hotelsvalue = useSelector((state) => state.hotelsContext.hotelsvalue);
  const selectedItemInfo = useSelector(
    (state) => state.hotelsContext.selectedItemInfo
  );

  // ##### 24-05-17 Komarov
  const history = useHistory();

  useEffect(() => {
    const selectedItemInfo =
      localStorage.getItem("selectedItemInfo") !== "undefined" &&
      JSON.parse(localStorage.getItem("selectedItemInfo"));
    if (
      selectedItemInfo &&
      history.location.pathname.includes("search_results_hotels")
    ) {
      dispatch(setItemInfo(selectedItemInfo));
      localStorage.removeItem("selectedItemInfo");
    }
  }, [history.location.pathname, dispatch]);

  const pickedHotelValue = useSelector(
    (state) => state.hotelsContext.pickedHotelValue
  );
  const hotelDatesDivOpened = useSelector(
    (state) => state.hotelsContext.hotelDatesDivOpened
  );
  // ##### 24-05-13 Komarov
  const pickedStartDate = useSelector(
    (state) => state.hotelsContext.pickedStartDate
  );
  const pickedEndDate = useSelector(
    (state) => state.hotelsContext.pickedEndDate
  );
  const autocompleteDivOpened = useSelector(
    (state) => state.hotelsContext.autocompleteDivOpened
  );
  const paxChoiceDivOpened = useSelector(
    (state) => state.hotelsContext.paxChoiceDivOpened
  );

  // @@@@@ 23-03-03 Komarov
  const translatedHotelsFromState = useSelector(
    (state) => state.hotels.hotelstrans
  );

  // ##### 24-07-25 Komarov
  const smart_translated_hotels = makeArray(translatedHotelsFromState[locale]).sort((a, b) => a.city - b.city);

  // ##### 24-05-13 Komarov
  useEffect(() => {
    const adults = query?.adults || searchProps?.adults;
    if (adults) {
      dispatch(setCounterAdultsOuter(adults));
    }
  }, []);

  useEffect(() => {
    const children = query?.children || searchProps?.children;
    if (children) {
      dispatch(setCounterChildOuter(children));
    }
  }, []);

  useEffect(() => {
    const rooms = query?.rooms || searchProps?.rooms;
    if (rooms) {
      dispatch(setCounterRoomsOuter(rooms));
    }
  }, []);

  // ##### 24-07-25 Komarov
  const hotels = useSelector(state => state.hotels.hotels);

  useEffect(() => {
    if (hotels.length > 0) {
      return;
    }
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocompleteSmallScreen.js:getHotels()"
      );
    dispatch(getHotels());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[SMART_HOTELS] : ' , smart_hotels)

  // ##### 24-07-25 Komarov
  const general_hotels = useSelector(state => state.hotels.general_hotels);

  useEffect(() => {
    // ##### 24-07-25 Komarov
    if (general_hotels.length > 0) {
      return;
    }
    // ##### 23-03-06 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/HotelsAutocompleteBlock/HotelsAutocompleteSmallScreen.js:getGeneralHotels()"
      );
    dispatch(getGeneralHotels());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[SMART_GENERAL_HOTELS] : ' , general_smart_hotels)

  // ##### 24-04-05 Komarov
  useEffect(() => {
    dispatch(setItemInfo(selectedItemInfo));
  }, [selectedItemInfo, dispatch]);

  // ##### 24-05-10 Komarov
  const searchClicked = useSelector(
    (state) => state.hotelsContext.searchClicked
  );

  useEffect(() => {
    // ##### 24-05-10 Komarov
    dispatch(
      setFormFilled(
        pickedHotelValue !== false &&
        hotelsvalue !== "" &&
        pickedStartDate !== false &&
        pickedEndDate !== false
      )
    );
  }, [pickedHotelValue, hotelsvalue, pickedStartDate, pickedEndDate]);

  // ##### 24-05-09 Komarov: Сохраняем значения текущего состояния в Redux
  useEffect(() => {
    dispatch(
      saveHotelsContext({
        stayStartDate,
        stayEndDate,
        hotelsvalue,
        hotelDatesDivOpened,
        smart_translated_hotels,
        props,
      })
    );
  }, []);

  return (
    <div className={wrapper} data-component="HotelsAutocompleteSmallScreen">
      {/* ##### 22-11-02 Komarov: div-обёртка (модальное окно) для div`а с содержимым Autocomplete */}
      <>
        {autocompleteDivOpened && (
          <div
            style={{
              // display: autocompleteDivOpened ? "inline-flex" : "none",
              display: "inline-flex",
              flexDirection: "column",
              width: "100%",
              // ##### 23-09-06 Komarov
              height: "100%",
              backgroundColor: "white",
              // border: '#196fbb',
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              position: "absolute",
              // ##### 23-09-06 Komarov
              // inset: '80px 0px 40px',
              zIndex: 2001,
              top: window.scrollY,
              left: "0px",
              // ##### 22-11-11 Komarov
              alignItems: "center",
            }}
          >
            {/* ##### 22-11-02 Komarov: div с содержимым Autocomplete */}
            <Media greaterThanOrEqual="Mobile-L-425-768">
              <AutocompleteOuterDiv
                cityHotelInput={cityHotelInput}
              />
            </Media>
            <Media lessThan="Mobile-L-425-768">
              <AutocompleteOuterDiv
                cityHotelInput={cityHotelInput}
              />
            </Media>
          </div>
        )}
        {/* ##### 24-05-15 Komarov: div-обёртка (модальное окно) для div`а с содержимым HotelDates */}
        {hotelDatesDivOpened && <HotelDatesModalDiv />}
        {/* ##### 22-11-02 Komarov: div-обёртка (модальное окно) для div`а с содержимым PaxChoice */}
        {paxChoiceDivOpened && (
          <div
            style={{
              // display: paxChoiceDivOpened ? "inline-flex" : "none",
              display: "inline-flex",
              flexDirection: "column",
              width: "100%",
              // ##### 23-09-06 Komarov
              height: "100%",
              backgroundColor: "white",
              // border: '#196fbb',
              border: "#e59700",
              borderStyle: "solid",
              borderRadius: "1rem",
              position: "absolute",
              // ##### 23-09-06 Komarov
              // inset: '80px 0px 40px',
              top: window.scrollY,
              left: "0px",
              zIndex: 2001,
              // ##### 22-11-11 Komarov
              alignItems: "center",
            }}
          >
            {/* ##### 22-11-02 Komarov: div с содержимым PaxChoice */}
            <Media greaterThanOrEqual="Mobile-L-425-768">
              <PaxChoice
                // ##### 24-02-08 Komarov
                // transform='translateY(-105px)'
                transform="translate3d(22px, -65px, 0)"
              />
            </Media>
            <Media lessThan="Mobile-L-425-768">
              <PaxChoice transform="translate3d(0.5vw, 176px, 0px)" />
            </Media>
          </div>
        )}

        {/* ##### 22-11-02 Komarov: форма поиска */}
        <form className={formClass} onSubmit={onSubmit}>
          {/* ##### 22-10-25 Komarov - start */}
          {/* ##### 22-11-02 Komarov: внешний div для формы поиска для клика для открытия div`а-обёртки (модального окна) для div`а с содержимым Autocomplete */}
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_01_CityOrHotel
              width="77vw"
              height="2.5em"
              cityHotelInput={cityHotelInput}
            />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              (pickedHotelValue === false || hotelsvalue === "") && (
                <ClickableLayer_01_CityOrHotel_Alert
                  width="77vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_01_CityOrHotel width="68vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              (pickedHotelValue === false || hotelsvalue === "") && (
                <ClickableLayer_01_CityOrHotel_Alert
                  width="68vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_02_StartEndDates width="77vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              pickedStartDate === false &&
              pickedEndDate === false && (
                <ClickableLayer_02_StartEndDates_Alert
                  width="77vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_02_StartEndDates width="68vw" height="2.5em" />
            {/* ##### 23-12-11 Komarov */}
            {searchClicked &&
              pickedStartDate === false &&
              pickedEndDate === false && (
                <ClickableLayer_02_StartEndDates_Alert
                  width="68vw"
                  height="2.5em"
                />
              )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_03_PaxChoice width="77vw" height="2.5em" />
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_03_PaxChoice width="68vw" height="2.5em" />
          </Media>
          {/* ##### 23-05-08 Komarov */}
          <Media lessThan="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="77vw" />
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="68vw" />
          </Media>
        </form>
      </>
    </div>
  );
};
