// ##### 24-02-26 Komarov
import { SET_WHITELABEL } from "../constants";

const initState = {
  set: false,
  // ##### 24-03-14 Komarov
  isDemo: false,
  // ##### 25-02-12 Komarov
  company_cipher: '',
  data: null,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_WHITELABEL:
      return {
        ...state,
        set: action.payload.set,
        // ##### 24-03-14 Komarov
        isDemo: action.payload.isDemo,
        // ##### 24-03-01 Komarov
        company_cipher: action.payload.company_cipher,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
