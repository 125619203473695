// ##### 25-02-05 Komarov
import React, { createContext, useMemo, useRef } from "react";
// ##### 23-09-22 Komarov
import { useSelector } from "react-redux";
import { LargeScreenSearch } from "./LargeScreenSearch";
// ##### 23-11-02 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { SmallScreenSearchTag } from "./SmallScreenSearchTag";

// ##### 23-09-14 Komarov
// import './Search.css';
// import './SwitcherFront.css';
// import 'antd/dist/antd.css';
// import './SmallScreenSearchCSS.css'

// ##### 24-05-22 Komarov
export const SearchContext = createContext();

// ##### 23-09-21 Komarov
export const Search = ({ searchProps, style }) => {
  // ##### 24-03-21 Komarov
  // const promoCode = useSelector(state => state.promocode.promocode);
  // ##### 24-02-28 Komarov
  const isWhiteLabelSet = useSelector((state) => state.whitelabel.set);
  // ##### 24-05-22 Komarov
  const switcherRef = useRef();

  // ##### 24-09-09 Komarov
  const searchContextValue = useMemo(() => ({
    searchProps,
    switcherRef,
  }), [
    // ##### 24-09-11 Komarov
    searchProps,
    switcherRef,
  ]); // value is cached by useMemo

  // ##### 23-02-14 Komarov
  return (
    // ##### 24-05-22 Komarov
    <SearchContext.Provider
      value={searchContextValue}
    >
      {/* ##### 24-02-28 Komarov */}
      <div
        data-component="Search"
        // ##### 23-10-25 Komarov
        style={
          (!isWhiteLabelSet && {
            marginTop: "2vh",
            // ##### 24-03-21 Komarov
            order: style?.order || null,
          }) || {
            // ##### 24-03-21 Komarov
            order: style?.order || null,
          }
        }
      >
        {/* ##### 23-11-02 Komarov */}
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <LargeScreenSearch
            formClass="mySearch"
            autocompleteClass="Autocomplete"
            datepickerClass="datePicker"
            searchProps={searchProps}
          />
        </Media>
        {/* ##### 23-11-02 Komarov */}
        <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
          <SmallScreenSearchTag
            wrapper="formOuterWrapper_inner_SmallLandscape"
            datepickerClass="datePicker_inner_MobileLandscape"
          />
        </Media>
        <Media lessThan="Mobile-L-425-768">
          <SmallScreenSearchTag
            wrapper="formOuterWrapper_inner_SmallPortrait"
            datepickerClass="datePicker_inner"
          />
        </Media>
      </div>
    </SearchContext.Provider>
  );
};
