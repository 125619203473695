// ##### 25-02-05 Komarov
import React, { createContext, useMemo } from "react";
// ##### 24-02-22 Komarov
// ##### 25-01-22 Komarov
// ##### 23-10-18 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { CurrencySelect } from "./CurrencySelect";

export const CurrencySelectionToolContext = createContext();

// ##### 23-04-24 Komarov
export const CurrencySelectionTool = ({ path }) => {
  // console.log('[A_PATH]',path)

  // dispatch(getPickedCurrencyResponse(currency))
  // ##### 24-09-11 Komarov
  const currencySelectionToolContextValue = useMemo(() => ({ path }), [path]);

  return (
    <CurrencySelectionToolContext.Provider
      value={currencySelectionToolContextValue}
    >
      <div style={{ marginRight: "1vw" }}>
        {/* ##### 23-11-02 Komarov */}
        <Media lessThan="Mobile-L-425-768">
          <CurrencySelect
            dropdownStyle={{
              scale: "none",
              transformOrigin: "top left",
            }}
          />
        </Media>
        <Media between={["Mobile-L-425-768", "Laptop-1024-1440"]}>
          <CurrencySelect
            dropdownStyle={{
              scale: "1.1",
              transformOrigin: "top left",
            }}
          />
        </Media>
        <Media greaterThanOrEqual="Laptop-1024-1440">
          <CurrencySelect />
        </Media>
      </div>
    </CurrencySelectionToolContext.Provider>
  );
};
