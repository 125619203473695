import axios from "../helpers/public.axios";

import {
  GET_HOTEL_CONTENT_ERR,
  GET_HOTEL_CONTENT_REQ,
  GET_HOTEL_CONTENT_RES,
  // ##### 25-02-12 Komarov
  GET_HOTEL_CONTENT_ERR2,
  GET_HOTEL_CONTENT_REQ2,
  GET_HOTEL_CONTENT_RES2,
} from "../constants";
// ##### 25-02-12 Komarov
import { getUrl, getPostObject } from "../../helpers/crypto";

//action creator
export const hotelContentResponse = (res) => ({
  type: GET_HOTEL_CONTENT_RES,
  payload: res,
});

// ##### 25-02-12 Komarov
export const hotelContentResponse2 = (res) => ({
  type: GET_HOTEL_CONTENT_RES2,
  payload: res,
});

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-09-16 Komarov
// TODO: Проверить и если надо, заменить на получение контента отеля через контроллер Xmlsubj
export const getHotelContent = (id, lang, user_id) => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent");

  dispatch({ type: GET_HOTEL_CONTENT_REQ });

  // ##### 24-09-11 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${id}&language=${lang}`;
  // ##### 25-01-10 Komarov
  // const url = `${process.env.REACT_APP_SMART_URL}interface/hotelcontent?id=${id}&language=${lang}&hotelview=1&user_id=${user_id}`;
  const url = getUrl('hotelcontent', `id=${id}&language=${lang}&hotelview=1&user_id=${user_id}`);
  process.env.NODE_ENV === "development" &&
    console.log(
      "axios:src/Redux/actions/hotelcontent.js:getHotelContent, url:",
      url
    );
  axios
    .get(url)
    // ##### 24-11-01 Komarov
    .then(async (res) => {
      const filtered_res = await res.data.filter(
        (item, index, array) =>
          array.findIndex((t) => t.content_name === item.content_name) === index
      );

      dispatch(hotelContentResponse(filtered_res));
      // console.log('[REDUX_HOTEL_CONTENT]:' , res.data)
    })

    .catch((err) => {
      dispatch({ type: GET_HOTEL_CONTENT_ERR, error: err });
    });
};

// ##### 25-02-12 Komarov
export const getHotelContent2 = ({
  hotel_id,
  city_id,
  language,
  user_id,
  refpartner,
  sale,
  promocode,
  deviceType,
  company_cipher,
  content_size = 1,
  summary = 0
}) => (dispatch) => {
  // ##### 23-03-06 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("axios:src/Redux/actions/hotelcontent.js:getHotelContent2");

  dispatch({ type: GET_HOTEL_CONTENT_REQ2 });
    const data = {
      sale_id: (sale && sale) || null,
      promocode,
      deviceType,
      company_cipher: company_cipher || '',
      city_id,
      hotel_id: hotel_id || 0,
      content_size,
      summary
    };

    const postObject = getPostObject({
      action: "GetHotelContentAlphaRQ",
      user_id,
      refpartner,
      language,
      sale: (sale && sale) || null,
      data
    });

    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/SecondPageofSearchModule/Hotels/HotelItems.js:useEffect, url:",
        postObject.url
      );
      // ##### 25-01-11 Komarov
    axios
      .post(
        postObject.url,
        postObject.parameters
      )
      // ##### 24-11-01 Komarov
      .then(async (response) => {
        dispatch(hotelContentResponse2(await response.data));
        console.log('GetHotelContentAlphaRQ', await response.data);
      })
      .catch((error) => {
        console.log("[axios error] : ", error);
        dispatch({ type: GET_HOTEL_CONTENT_ERR2, error });
      });
};
