// ##### 25-02-05 Komarov
import { PlaceHolderStrings } from "../../../../../Localization/placeholders";
import { useMessages } from "../../../../../../../helpers/messages";
import uniqueId from "lodash.uniqueid";
import { Select } from "antd";
import React from "react";

export const MealComponent = ({ props }) => {
  const {
    selectMealType,
    mealType,
    mealTypeInitialState
  } = props;
  const placeholder = PlaceHolderStrings();

  const mealTypes = [
    useMessages("All Inclusive"),
    useMessages("Full board"),
    useMessages("Breakfast"),
    useMessages("Lunch"),
    useMessages("Dinner"),
    useMessages("Half board"),
    useMessages("Breakfast and Lanch"),
    useMessages("Lunch and Dinner"),
    useMessages("Without Food")
  ];

  const chooseTypeOfMeal = useMessages("chooseTypeOfMeal");
  return (<div className="groupTravelblocks_2" key={uniqueId()}>
      <span>{chooseTypeOfMeal}</span>
      <Select
        onChange={selectMealType}
        bordered={true}
        style={{ minWidth: "5vw" }}
        key={uniqueId()}
        // ##### 24-03-21 Komarov
        value={
          (mealType === mealTypeInitialState &&
            placeholder.placeHolderSelect) ||
          mealType
        }
      >
        <>
          {
            // ##### 24-03-21 Komarov
            mealTypes?.map((item) => (
              <Option value={item} key={item}>
                {item}
              </Option>
            ))
          }
        </>
      </Select>
    </div>
  );
};