// ##### 25-02-05 Komarov
import React, { useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";

export const CooperationMobile = ({ pages, title, isWhiteLabelSet, companyCipher, sidebarListWidth }) => {
  const cooperationRef = useRef();
  const cooperationItemsRef = useRef();

  useEffect(() => {
    cooperationItemsRef.current.style.right = `${sidebarListWidth}px`;
    cooperationItemsRef.current.style.top = `${cooperationRef.current.offsetTop}px`;
  }, [cooperationItemsRef.current]);

  const removeCooperationItems = () => {
    const cooperationItemsclassList = cooperationItemsRef.current.classList;
    if (!cooperationItemsclassList.contains("CooperationMobileItemsHide")) {
      cooperationItemsclassList.add("CooperationMobileItemsHide");
    }
  };

  const toggleCooperationItems = (e) => {
    cooperationItemsRef.current.classList.toggle("CooperationMobileItemsHide");
    e.stopPropagation();
  };

  return <OutsideClickHandler
    onOutsideClick={removeCooperationItems}>
    <a
      ref={cooperationRef}
      role="button"
      data-component="CooperationMobile"
      onClick={toggleCooperationItems}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          toggleCooperationItems();
        }
      }}
    >
      <h4>{title}</h4>
    </a>
    <div
      className="CooperationMobileItemsHide"
      data-component="CooperationMobileItems"
      ref={cooperationItemsRef}
    >
      {pages.map((page) => {
        return (
          // ##### 24-03-04 Komarov

          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
            onClick={removeCooperationItems}
          >
            <h4>{parse(page.title)}</h4>
          </LocalizationNavLink>

        );
      })}
    </div>
  </OutsideClickHandler>;
};