// ##### 25-02-12 Komarov
import { useMessages } from "../../../../../helpers/messages";
import React from "react";
import { useSelector } from "react-redux";

export const PropertiesFoundHeader = () => {
  const PropertiesFound = useMessages("PropertiesFound");
  const hotelcontent2Data = useSelector(state => state.hotelcontent.hotelcontent2.data);
  // const hotelsCount = Object.keys(hotelcontent2Data.hotels).length;
  // TODO: Разный набор отелей в action'ах GetHotelAvailAlphaRQ и GetHotelContentAlphaRQ, что приводит к ошибкам на сайте.
  // Поэтому для подсчёта количества найденных отелей берём отели, которые находятся в "GetHotelAvailAlphaRS" и не содержат свойство "notdispl" со значением, приводимым к логическому true (число больше 0) и которые присутствуют в "GetHotelAvailAlphaRS"
  const hotels_avail = useSelector(state => state.hotels.hotels_avail.data.hotels);
  const hotelsCount = Object.keys(hotels_avail).filter((hotel_id) => {
    if (!!!parseInt(hotels_avail[hotel_id].notdispl)) return hotel_id in hotelcontent2Data.hotels;
  }).length;
  
  const cityName = hotelcontent2Data.ci_title;
  return <h2
    data-component="PropertiesFoundHeader"
    className="PropertiesFound"
  >
    {/* ##### 24-03-21 Komarov */}
    <span>
    {cityName}: {hotelsCount}
      <span style={{ marginRight: "5px" }} />
      {
        // ##### 23-10-04 Komarov
        PropertiesFound
      }
  </span>
  </h2>;
};