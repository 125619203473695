// ##### 24-06-13 Komarov
import React, { useContext } from "react";
import { HotelsPaxChoiceContext } from "./HotelsPaxChoice.js";
// ##### 25-02-04 Komarov
import { useMessages } from "../../../../../helpers/messages.js";

// ##### 25-02-04 Komarov
export const PaxPopUpButton = ({ marginTop = null }) => {
  const { toggleContextPaxListOpen } = useContext(
    HotelsPaxChoiceContext
  );
  const Confirm = useMessages("Confirm");
  return (
    <button
      data-component="PaxPopUpButton"
      type="button"
      className="PopUpButton"
      // ##### 23-04-28 Komarov
      onClick={(e) => {
        toggleContextPaxListOpen(false);
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        // Trigger click action on Enter key press
        if (e.key === "Enter") {
          toggleContextPaxListOpen(false);
          e.stopPropagation();
        }
      }}
      // ##### 23-03-01 Komarov
      style={{ marginTop }}
    >
      {/* ##### 23-09-14 Komarov */}
      {
        // ##### 23-10-04 Komarov
        Confirm}
    </button>
  );
};
