// ##### 25-02-05 Komarov
import React from "react";

export const BodyPageImg = ({ item, style = null }) => (
  <img
    data-component="BodyPageImg"
    // ##### 24-03-21 Komarov
    alt="Banner"
    style={style}
    src={`${process.env.REACT_APP_PROTOCOL + item.text}`}
  />
);