// import axios from '../helpers/public.axios';

// ##### 23-03-29 Komarov
import { GET_AVAIL_REQ, GET_AVAIL_RES } from "../constants";

//action creator
export const availResponse = (res) => ({
  type: GET_AVAIL_RES,
  payload: res,
});

// console.log('[CONTENTRESPONCE]' , contentResponse)

// ##### 24-03-21 Komarov
export const getAvail = (value, index) => (dispatch) => {
  // console.log('ACTIVEINDEX',activeIndex)
  dispatch({ type: GET_AVAIL_REQ });

  const avail_obj = {
    availability: value,
    activeIndex: index,
    picked: true,
  };

  dispatch(availResponse(avail_obj));
  // console.log('GET_AVAIL', avail_obj)
};
