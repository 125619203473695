// ##### 25-02-05 Komarov
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ArkturDMClogoICO from "../../../Library/Images/ArkturDMClogoICO.ico";

export const CONTACTUS = () => {
  // ##### 24-05-31 Komarov
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-component="CONTACTUS">
      <Helmet>
        <title>Contact Arktur DMC</title>
        <link rel="shortcut icon" href={ArkturDMClogoICO} />
      </Helmet>
      <h2> Our Contact details</h2>
    </div>
  );
};