// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert = ({
  width,
  height
}) => {
  const { chooseYourDestination } = useContext(
    ToursAutocompleteSmallScreenContext
  );
  return (
    <div
      data-component="ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert"
      id="clickableLayer_01_ToursAutocompleteSmallScreen_Alert"
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width: width,
        height: height,
        border: "red",
        borderStyle: "solid",
        borderRadius: "1rem",
        fontFamily: "Arial",
        alignItems: "center",
        // ##### 23-04-26 Komarov
        margin: "0.3vw"
      }}
    >
      {/* ##### 23-03-30 Komarov */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          // marginTop: "0.25em",
          textAlign: "center",
          lineHeight: 1,
          color: "red"
        }}
      >
        {chooseYourDestination}
      </div>
    </div>
  );
};