// ##### 24-05-21 Komarov
import moment from "moment";
// ##### 25-02-05 Komarov
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { Hotels } from "../../../Library/Icons/hotels";
import { HotelsAutocomplete } from "../HotelsAutocompleteBlock/HotelsAutocomplete/HotelsAutocomplete";
import { ToursAutocomplete } from "../ToursAutocompleteBlock/ToursAutocomplete";
// ##### 25-01-22 Komarov
import { getTogglerType } from "../../../../Redux/actions";

// ##### 23-09-14 Komarov
// import './Search.css';
// import './SwitcherFront.css';
// import 'antd/dist/antd.css';
import config from "../../../../Redux/config";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 25-02-05 Komarov
import { SwitcherTag } from "./SwitcherTag";

moment.locale(config.defaultLang);

// ##### 24-05-21 Komarov
export const LargeScreenSearchContext = createContext();

// ##### 24-03-21 Komarov
export const LargeScreenSearch = ({
  formClass,
  datepickerClass,
  searchProps,
}) => {
  // ##### 24-04-23 Komarov
  const hotels = useMessages("Hotels");
  const tours = useMessages("Tours");

  const dispatch = useDispatch();
  // const [list , setList] = useState([]);
  // ##### 23-11-10 Komarov
  // const [generalValue, setGeneralValue] = useState('');
  const [align, setAlign] = useState("HOTELS");
  // const [generalList, setGeneralList] = useState([])

  useEffect(() => {
    dispatch(getTogglerType(align));
    // ##### 23-04-12 Komarov
  }, [align, dispatch]);

  const toggler = (me) => (_) => {
    setAlign(me);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // ##### 25-01-22 Komarov
  const largeScreenSearchContextValue = useMemo(() => ({
    toggler,
    align,
    hotels,
    tours,
  }), [
    align,
    hotels,
    tours
  ]); // value is cached by useMemo

  // ##### 24-05-21 Komarov
  return (
    <LargeScreenSearchContext.Provider
      value={largeScreenSearchContextValue}
    >
      <div
        data-component="LargeScreenSearch"
        // ##### 23-11-10 Komarov
        className="largeScreenSearch"
      // onClick={paxListOpen ? MakeVisible : null}
      >
        {/* ##### 23-11-02 Komarov */}
        {/* <div className='greaterThanOrEqual-tabletMaxWidth_960'>
        <SwitcherTag />
      </div> */}
        {/* <Media greaterThanOrEqual='Laptop-1024-1440'>
        <SwitcherTag />
      </Media> */}
        <SwitcherTag />

        <>
          {align === "TOURS" ? (
            <>
              <ToursAutocomplete
                formClass={formClass}
                onSubmit={onSubmit}
                datepickerClass={datepickerClass}
                // ##### 23-11-10 Komarov
                // GeneralListFunction={GeneralListFunction}
                searchProps={searchProps}
              />

              {/* ##### 23-11-10 Komarov  */}
              {/* <>
              {
                generalList.length > 0 && <GuestItem
                // title={generalValue}
                // list={generalList}
                />
              }
            </> */}
            </>
          ) : (
            <>
              <HotelsAutocomplete
                formClass={formClass}
                // ##### 24-09-10 Komarov
                // onSubmit={onSubmit}
                datepickerClass={datepickerClass}
                // ##### 23-11-10 Komarov
                // GeneralListFunction={GeneralListFunction}
                searchProps={searchProps}
              />
              {/* ##### 23-11-10 Komarov */}
              {/* <>
                {
                  generalList.length > 0 && <HotelItems
                  // title={generalValue}
                  // list={generalList}
                  />
                }
              </> */}
            </>
          )}
        </>
      </div>
    </LargeScreenSearchContext.Provider>
  );
};
