// ##### 24-09-09 Komarov
import moment from "moment";
// ##### 25-02-05 Komarov
import React, { createContext, useEffect, useMemo, useState } from "react";
// ##### 23-12-18 Komarov
import en from "antd/es/locale/en_US";
import fr from "antd/es/locale/fr_FR";
import ru from "antd/es/locale/ru_RU";
import tr from "antd/es/locale/tr_TR";
import uk from "antd/es/locale/uk_UA";
import { month } from "../../../../helpers/dateFormats";
// ##### 23-11-21 Komarov
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGeneralGeo, getGeo } from "../../../../Redux/actions";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-11-02 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-04-05 Komarov
// ##### 24-04-17 Komarov
import { makeParamsString } from "../../Helpers/helper";
// ##### 25-02-05 Komarov
import { AutocompleteOuterDivTag } from "./AutocompleteOuterDivTag";
import {
  ClickableLayer_01_ToursAutocompleteSmallScreenDivTag
} from "./ClickableLayer_01_ToursAutocompleteSmallScreenDivTag";
import {
  ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert
} from "./ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert";
import {
  ClickableLayer_02_ToursAutocompleteSmallScreenDivTag
} from "./ClickableLayer_02_ToursAutocompleteSmallScreenDivTag";
import {
  ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert
} from "./ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert";
import { TourMonthDiv } from "./TourMonthDiv";
import { BorderInnerWrapper2DivTag } from "./BorderInnerWrapper2DivTag";

// ##### 23-12-18 Komarov
export const dateLocales = {
  en,
  uk,
  tr,
  fr,
  ru,
};

export const ToursAutocompleteSmallScreenContext = createContext();

// ##### 23-11-21 Komarov
export const ToursAutocompleteSmallScreen = ({
  wrapper,
  formClass,
  datepickerClass,
  props,
  searchProps,
}) => {
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // ##### 24-03-21 Komarov
  // const [date, setDate] = useState("");
  // ##### 23-03-30 Komarov
  const [tourDate, setTourDate] = useState("");
  const [list, setList] = useState([]);
  // ##### 23-12-18 Komarov
  const [tourValue, setTourValue] = useState("");
  const [open, setOpen] = useState(false);
  // ##### 23-11-21 Komarov
  const [pickedTourValue, setPickedTourValue] = useState(
    !!searchProps
  );
  // ##### 23-12-12 Komarov
  const [pickedTourMonth, setPickedTourMonth] = useState(
    !!searchProps
  );
  // ##### 23-12-12 Komarov
  const [searchClicked, setSearchClicked] = useState(false);
  const [formFilled, setFormFilled] = useState(false);

  // ##### 23-12-13 Komarov
  useEffect(() => {
    setFormFilled(
      pickedTourValue !== false && tourValue !== "" && pickedTourMonth !== false
    );
  }, [pickedTourValue, tourValue, pickedTourMonth]);

  const history = useHistory();

  const dispatch = useDispatch();
  const geo = useSelector((state) => state.cities.locs);
  // ##### 23-04-12 Komarov
  const geoGeneralFromState = useSelector((state) => state.cities.gen_locs);

  // ##### 23-04-07 Komarov
  const deviceType = useSelector((state) => state.deviceType);

  // ##### 24-02-22 Komarov
  const pickedcurrencyFromState = useSelector(
    (state) => state.currency.pickedcurrency
  );
  // ##### 24-07-25 Komarov
  const pickedcurrency = pickedcurrencyFromState || JSON.parse(localStorage.getItem("currency")).pickedcurrency;

  // const dateFormat = 'DD-MM-YYYY'
  // ##### 24-07-25 Komarov
  const citiesLocs = useSelector(state => state.cities.locs);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/ToursAutocompleteBlock/ToursAutocompleteSmallScreen.js:getGeo()"
      );
    // ##### 23-03-28 Komarov
    //  dispatch (getGeo ());
    // ##### 24-07-25 Komarov
    citiesLocs.length > 0 || dispatch(getGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[GEO] : ' , geo)
  // ##### 24-07-25 Komarov
  const citiesGenLocs = useSelector(state => state.cities.gen_locs);

  // ##### 23-03-28 Komarov
  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/FirstPageofSearchModule/ToursAutocompleteBlock/ToursAutocompleteSmallScreen.js:getGeneralGeo()"
      );
    // dispatch (getGeneralGeo ());
    // ##### 24-07-25 Komarov
    citiesGenLocs.length > 0 ||
      dispatch(getGeneralGeo());
    // ##### 23-04-12 Komarov
  }, [dispatch]);
  // console.log('[general_GEO] : ' , geoGeneral)

  // ##### 24-03-21 Komarov
  function onChange(_date, dateString) {
    // ##### 23-12-12 Komarov
    setTourDate(dateString);
    setPickedTourMonth(true);
  }

  const optionChecker = (e) => {
    // console.log('[VALUE] : ', e.target.value)
    setTourValue(e.target.value);
    if (tourValue.length > 0) {
      setOpen(true);
    }
  };

  // console.log('[TODAY DATE]' , moment().format('YYYY-MM') , '[TEST_DATE] : ' , testDate)

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current < moment().endOf("day");
  };
  // ##### 24-04-23 Komarov
  const chooseYourDestination =
    useMessages("ChooseYourDestination") || "Please choose your hotel";
  const chooseMonth = useMessages("ChooseMonth") || "Please choose Month";
  // ##### 24-03-01 Komarov
  const whitelabel = useSelector((state) => state.whitelabel);
  const isWhiteLabelSet = whitelabel.set;
  const company_cipher = whitelabel.company_cipher;
  const promoCode = useSelector((state) => state.promocode.promocode);

  const addToList = () => {
    // ##### 23-12-12 Komarov
    setSearchClicked(true);
    // ##### 23-12-12 Komarov
    if (!formFilled) {
      return;
    }
    // ##### 24-03-21 Komarov
    const filtered = geo.filter((item) => item.name === tourValue);

    // ##### 24-07-25 Komarov
    const filtered_city_id = geoGeneralFromState.filter((item) => {
      if (parseInt(item.tour_id) === parseInt(filtered[0].id)) {
        return parseInt(item.city_id);
      }
      // ##### 24-03-21 Komarov
      return parseInt(item.city_id) === parseInt(filtered[0].id);
    });

    // console.log('FILTERED_CITY_ID', filtered_city_id )

    const newList = {
      id: filtered[0].id,
      title: tourValue,
      // ##### 23-03-30 Komarov
      date: tourDate,
    };

    setList([...list, newList]);
    // ##### 24-03-21 Komarov
    // setDate("");
    setTourValue("");

    const params_string = makeParamsString({
      selected_currency: pickedcurrency,
      title: tourValue,
      date:
        (moment(tourDate, month[locale]).format(month.en).isValid() &&
          moment(tourDate, month[locale]).format(month.en)) ||
        moment(tourDate, month.en).format(month.en),
      id: filtered[0].id,
      city_id: filtered_city_id[0].city_id,
    });

    // ##### 24-02-29 Komarov: TODO: Убрать промокод из URL
    if (isWhiteLabelSet) {
      const route_query = `?${params_string}`;

      history.push(
        `/${locale}/ref${company_cipher}/search_results_tours/${route_query}`,
        [...list, newList]
      );
    } else if (promoCode) {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_tours/${route_query}`, [
        ...list,
        newList,
      ]);
    } else {
      const route_query = `?${params_string}`;

      history.push(`/${locale}/search_results_tours/${route_query}`, [
        ...list,
        newList,
      ]);
    }
    // console.log('[HISTORY : ] ', history)
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  // ##### 23-03-16 Komarov - start
  // ##### 23-03-08 Komarov: переменная для определения открытости окна выбора города или тура
  const [autocompleteDivOpened, setAutocompleteDivOpened] = useState(false);
  // ##### 23-03-08 Komarov: переменная для определения открытости окна выбора месяца проведения тура
  const [isTourMonthDivOpened, setTourMonthDivOpened] = useState(false);

  // ##### 23-03-08 Komarov: Функция для обработки клика по элементу Autocomplete: он отображается в увеличенном масштабе, при этом его собственная область клика остаётся оригинального (маленького) размера, и оказывается смещённой влево. А я добавил оборачивающий его div, для которого подобрал высоту и ширину, и по клику по нему в данной функции устанавливаю фокус ввода на Autocomplete, и получается, что нажали как бы на него
  const onAutocompleteDivClick = (e) => {
    // console.log('e:', e);
    // ##### 24-03-21 Komarov
    if (e.target.tagName === "BUTTON") {
      setAutocompleteDivOpened(false);
    } else {
      setAutocompleteDivOpened(true);
    }
    // ##### 23-03-08 Komarov: проверяем наличие html-элемента (с id "cityOrTourNameInput")
    // ##### 24-03-21 Komarov
    document.querySelector("#cityOrTourNameInput")?.focus();
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  const onRangePickerDivClick = (e) => {
    // console.log('e.target.tagName:', e.target.tagName);
    // ##### 24-03-21 Komarov
    if (e.target.tagName === "BUTTON") {
      setTourMonthDivOpened(false);
    } else {
      setTourMonthDivOpened(true);
    }
    // ##### 24-03-21 Komarov
    document.querySelector("#monthInput")?.click();
    // ##### 22-10-25 Komarov
    e.stopPropagation(); // Вызываем эту функцию, чтобы событие не отлавливалось дочерними компонентами, чтобы избежать его срабатвыания два раза подряд: и на div'е, и на целевом компоненте (в данном случае - Autocomplete).
  };

  // ##### 25-01-22 Komarov
  const toursAutocompleteSmallScreenContextValue = useMemo(() => ({
    props,
    geo,
    setOpen,
    open,
    tourValue,
    optionChecker,
    setTourValue,
    onAutocompleteDivClick,
    onRangePickerDivClick,
    tourDate,
    onChange,
    disabledDate,
    // ##### 23-11-21 Komarov
    searchProps,
    setPickedTourValue,
    addToList,
    datepickerClass,
    isTourMonthDivOpened,
    // ##### 23-12-13 Komarov
    chooseYourDestination,
    chooseMonth,
  }), [
    props,
    geo,
    setOpen,
    open,
    tourValue,
    setTourValue,
    tourDate,
    searchProps,
    setPickedTourValue,
    datepickerClass,
    isTourMonthDivOpened
  ]);

  return (
    <div className={wrapper} data-component="ToursAutocompleteSmallScreen">
      {/* ##### 23-03-08 Komarov: div-обёртка (модальное окно) для div`а с содержимым Autocomplete */}
      <ToursAutocompleteSmallScreenContext.Provider
        value={toursAutocompleteSmallScreenContextValue}
      >
        <div
          style={{
            display: autocompleteDivOpened ? "inline-flex" : "none",
            flexDirection: "column",
            width: "100%",
            // ##### 23-11-21 Komarov
            // ##### 23-12-11 Komarov
            // height: '60vh',
            backgroundColor: "white",
            // border: '#196fbb',
            border: "#e59700",
            borderStyle: "solid",
            borderRadius: "1rem",
            position: "absolute",
            // inset: '15vh 0px 5vh',
            // inset: '150px 0px 40px',
            // ##### 22-11-07 Komarov
            inset: "80px 0px 40px",
            zIndex: 2001,
            // ##### 23-12-11 Komarov
            alignItems: "center",
            top: window.scrollY,
          }}
        >
          {/* ##### 22-11-02 Komarov: div с содержимым Autocomplete */}
          <AutocompleteOuterDivTag transform="translateY(25px)" />
          <div
            style={{
              position: "absolute",
              bottom: "40px",
              // width: '0',
            }}
          >
            {/* ##### 23-03-08 Komarov: div с кнопкой для закрытия div`а с содержимым Autocomplete */}
            <button
              // ##### 24-03-21 Komarov
              type="button"
              onClick={(e) => onAutocompleteDivClick(e)}
              style={{
                // ##### 22-11-07 Komarov
                // minWidth: '90%',
                backgroundColor: "#005AEB",
                color: "white",
                // fontSize: '32px',
                fontFamily: "Arial, Helvetica, sans-serif",
                borderRadius: "1rem",
                /* padding: '1vh 33vw, */
                padding: "6.75px 33vw",
                fontWeight: "bold",
                /* marginTop: '0.9vh, */
                // marginTop: '6.075px',
                // textAlign: 'center',
                /* marginBottom: '1vh, */
                // marginBottom: '6.75px',
              }}
            >
              OK
            </button>
          </div>
        </div>

        {/* clickableLayer_02_ToursAutocompleteSmallScreenDivTag */}
        {/* ##### 23-11-21 Komarov */}
        <div
          style={{
            display: isTourMonthDivOpened ? "inline-flex" : "none",
            // display: 'inline-flex',
            flexDirection: "column",
            width: "100%",
            height: "60vh",
            backgroundColor: "white",
            border: "#e59700",
            borderStyle: "solid",
            borderRadius: "1rem",
            position: "absolute",
            // inset: '80px 0px 40px',
            top: window.scrollY,
            left: "0px",
            zIndex: 2001,
            alignItems: "center",
          }}
        >
          {/* ##### 23-03-08 Komarov: div с содержимым TourMonth */}
          <TourMonthDiv transform="translateY(25px)" />

          {/* ##### 22-11-02 Komarov: div с кнопкой для закрытия div`а с содержимым TourMonth */}
          <div>
            <button
              // ##### 24-03-21 Komarov
              type="button"
              onClick={(e) => onRangePickerDivClick(e)}
              style={{
                backgroundColor: "#005AEB",
                color: "white",
                fontFamily: "Arial, Helvetica, sans-serif",
                borderRadius: "1rem",
                padding: "6.75px 33vw",
                fontWeight: "bold",
                // ##### 23-11-21 Komarov
                marginTop: "3vh",
              }}
            >
              OK
            </button>
          </div>
        </div>

        {/* ##### 23-03-08 Komarov: форма поиска */}
        <form className={formClass} onSubmit={onSubmit}>
          {/* ##### 23-03-08 Komarov: внешний div для формы поиска для клика для открытия div`а-обёртки (модального окна) для div`а с содержимым Autocomplete */}
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_01_ToursAutocompleteSmallScreenDivTag
              width="77vw"
              height="2.5em"
              transform="translateY(-4em)"
            />
            {/* ##### 23-12-13 Komarov */}
            {searchClicked && !pickedTourValue && (
              <ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert
                width="77vw"
                height="2.5em"
                transform="translateY(-4em)"
              />
            )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_01_ToursAutocompleteSmallScreenDivTag
              // ##### 24-03-21 Komarov
              width={(deviceType !== "tablet" && "77vw") || "68vw"}
              height="2.4em"
              transform="translateY(-2.1em)"
            />
            {/* ##### 23-12-13 Komarov */}
            {searchClicked && !pickedTourValue && (
              <ClickableLayer_01_ToursAutocompleteSmallScreenDivTag_Alert
                // ##### 24-03-21 Komarov
                width={(deviceType !== "tablet" && "77vw") || "68vw"}
                height="2.4em"
                transform="translateY(-2.1em)"
              />
            )}
          </Media>

          {/* ##### 23-03-08 Komarov: внешний div для формы поиска для клика для открытия div`а-обёртки (модального окна) для div`а с содержимым TourMonth */}
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <ClickableLayer_02_ToursAutocompleteSmallScreenDivTag
              width="77vw"
              height="2.5em"
              transform="translateY(-3.5em)"
            />
            {/* ##### 23-12-13 Komarov */}
            {searchClicked && !pickedTourMonth && (
              <ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert
                width="77vw"
                height="2.5em"
                transform="translateY(-3.5em)"
              />
            )}
          </Media>
          <Media lessThan="Mobile-L-425-768">
            <ClickableLayer_02_ToursAutocompleteSmallScreenDivTag
              // ##### 24-03-21 Komarov
              width={(deviceType !== "tablet" && "77vw") || "68vw"}
              height="2.4em"
              transform="translateY(-1.7em)"
            />
            {/* ##### 23-12-13 Komarov */}
            {searchClicked && !pickedTourMonth && (
              <ClickableLayer_02_ToursAutocompleteSmallScreenDivTag_Alert
                // ##### 24-03-21 Komarov
                width={(deviceType !== "tablet" && "77vw") || "68vw"}
                height="2.4em"
                transform="translateY(-1.7em)"
              />
            )}
          </Media>
          {/* ##### 23-03-16 Komarov - end */}

          {/* ##### 23-05-08 Komarov */}
          <Media lessThan="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="77vw" />
          </Media>
          <Media greaterThanOrEqual="Mobile-L-425-768">
            <BorderInnerWrapper2DivTag width="68vw" />
          </Media>
        </form>
      </ToursAutocompleteSmallScreenContext.Provider>
    </div>
  );
};
