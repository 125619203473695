// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { SearchContext } from "./SearchFront";
import { SwitcherContext } from "./Switcher";

export const SwitcherDiv = ({ className = null, width, buttonClassName = "" }) => {
  const { children, active, changeHandler } = useContext(SwitcherContext);
  // ##### 24-05-22 Komarov: switcherRef - ссылка для отображения react-портала в этом html-элементе
  const { switcherRef } = useContext(SearchContext);
  return (
    <div
      className={className}
      style={{ width }}
      ref={switcherRef}
      data-component="SwitcherDiv"
    >
      {" "}
      {React.Children.count(children) > 0 && (
        <>
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                active: child.props.value === active,
                changeHandler: changeHandler(child.props.value),
                // ##### 23-11-02 Komarov
                buttonClassName
              });
            }
          })}
        </>
      )}
    </div>
  );
};