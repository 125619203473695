// ##### 25-02-05 Komarov
import React from "react";

export const ImageShown = ({ width1, shownImgRef, firstPhotoUrl }) => {
  return (
    <div
      style={{
        width: width1,
        display: "flex",
        justifyContent: "center"
      }}
    >
      <img
        ref={shownImgRef}
        src={shownImgRef.current.src || firstPhotoUrl}
        // ##### 24-03-18 Komarov: TODO: Взять значение атрибута alt из Смарта
        alt="Picked gallery element"
        style={{
          padding: "5px",
          marginLeft: "auto",
          marginRight: "auto",
          // ##### 22-09-05 Komarov: сделал использование width, а не maxHeight
          width: "100%",
          // maxHeight:'100%',
          // height:'65vh',
          // maxHeight:'65vh',
          objectFit: "cover",
          //   height:`${photoHeight}`,
          borderRadius: "0.7vw"
          // overflow: "auto",
        }}
      />
    </div>
  );
};