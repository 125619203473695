import { SET_DEVICE_TYPE } from "../constants";
export const setDeviceType = (deviceType) => async (dispatch) => {
  // ##### 24-03-21 Komarov
  process.env.NODE_ENV === "development" &&
    console.log("src/Redux/actions/setDeviceType.js:deviceType", deviceType);
  dispatch({
    type: SET_DEVICE_TYPE,
    payload: deviceType,
  });
};
