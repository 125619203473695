// ##### 25-02-05 Komarov
import { useMessages } from "../../../helpers/messages";
import { useSelector } from "react-redux";
import React, { useContext } from "react";
import uniqueId from "lodash.uniqueid";
import { CartGalleryShortVersion } from "../../Library/PageDevices/CartGallery/CartGalleryShortVersion";
import parse from "html-react-parser";
import { LoadingMessage } from "../../Library/PageDevices/LoadingMessage";
import { CartDetailsContext } from "./CartDetails";

export const CartDetailsDiv = ({ className }) => {
  // ##### 24-04-23 Komarov
  const CartDetailsHeader = useMessages("CartDetailsHeader");
  const CartDetails_1 = useMessages("CartDetails_1");
  const CartDetails_2 = useMessages("CartDetails_2");
  const BookingAddDetails = useMessages("BookingAddDetails");
  const TaxDetails = useMessages("TaxDetails");

  const hotelcontents = useSelector((state) => state.hotelcontent.hotelcontent);
  const tourcontents = useSelector((state) => state.content.content);

  // ##### 24-04-18 Komarov
  const { cart, cartClass } =
    useContext(CartDetailsContext);

  const content =
    parseInt(cart.service_type_id) === parseInt("11")
      ? tourcontents
      : hotelcontents;

  return (
    <div
      className={`${cartClass ? cartClass : className}`}
      data-component="CartDetailsDiv"
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2
          style={{
            color: "#003057",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
            order: "-2"
          }}
        >
          {
            // ##### 23-10-04 Komarov
            CartDetailsHeader
          }
        </h2>
        {content.length > 0 ? (
          content.map((item) => {
            return (
              // ##### 24-04-17 Komarov
              <React.Fragment key={uniqueId()}>
                <h3
                  style={{
                    color: "#003057",
                    fontSize: "22px",
                    textAlign: "center",
                    order: "-1"
                  }}
                >
                  {item.content_name === "Title" ? item.text : null}
                </h3>
                <div style={{ order: "0" }}>
                  {item.content_name === "Image" ? (
                    <CartGalleryShortVersion photos={item} />
                  ) : null}
                </div>
                <div
                  style={{
                    order: "1",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  {item.content_name === "Summary" ? (
                    <>
                      <h4>
                        {
                          // ##### 23-10-04 Komarov
                          CartDetails_1
                        }
                      </h4>
                      <div
                        style={{
                          textAlign: "justify",
                          padding: "0.5vh"
                        }}
                      >
                        {parse(item.text)}
                      </div>
                    </>
                  ) : null}
                </div>

                <div
                  style={{
                    order: "2",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  {item.content_name === "Inclusion" ? (
                    <>
                      <h4>Tour Inclusions</h4>
                      <div
                        style={{
                          textAlign: "justify",
                          padding: "0.5vh"
                        }}
                      >
                        {parse(item.text)}
                      </div>
                    </>
                  ) : null}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <div>
            <LoadingMessage loadingMessageClass="RateLoading" />
          </div>
        )}
      </div>
      {/* <div>{cart.start}</div> */}
      <h4>
        {
          // ##### 23-10-04 Komarov
          CartDetails_2
        }
      </h4>
      <div className="MainRateDetails">

        <h5>
          {
            // ##### 23-10-04 Komarov
            BookingAddDetails
          }
        </h5>
        <div className="TaxDetails">
          {
            // ##### 23-10-04 Komarov
            TaxDetails
          }
        </div>
      </div>
    </div>
  );
};