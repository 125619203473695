// ##### 24-06-13 Komarov
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { useMessages } from "../../../../../helpers/messages.js";
import { HotelsPaxChoiceContext } from "./HotelsPaxChoice.js";

// ##### 25-02-04 Komarov
export const HotelsPaxChoiceGrid = ({ rowGap }) => {
  const {
    deductAdults, counterAdults, addAdults, deductChild, counterChild, addChild, deductRooms, counterRooms, addRooms,
  } = useContext(HotelsPaxChoiceContext);
  const twelve_plus_years = useMessages("twelve_plus_years");
  const two_eleven_years = useMessages("two_eleven_years");

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  return (
    <div
      data-component="HotelsPaxChoiceGrid"
      style={{
        display: "grid",
        gridTemplateRows: "repeat(3, 6vh)",
        rowGap,
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4>
          {
            // ##### 23-10-04 Komarov
            Adults}
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <MinusOutlined
            className="Minus"
            style={{ color: "white" }}
            onClick={deductAdults} />
        </h4>
        <h4>
          <center>
            <b>{counterAdults}</b>
          </center>
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <PlusOutlined
            className="Plus"
            style={{ color: "white" }}
            onClick={addAdults} />
        </h4>
        {/* ##### 23-07-06 Komarov */}
        <h4>
          <center>
            <i>
              (
              {
                // ##### 23-10-04 Komarov
                twelve_plus_years}
              )
            </i>
          </center>
        </h4>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4>
          {
            // ##### 23-10-04 Komarov
            Children}
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <MinusOutlined
            className="Minus"
            style={{ color: "white" }}
            onClick={deductChild} />
        </h4>
        <h4>
          <center>
            <b>{counterChild}</b>
          </center>
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <PlusOutlined
            className="Plus"
            style={{ color: "white" }}
            onClick={addChild} />
        </h4>
        {/* ##### 23-09-14 Komarov */}
        <h4>
          <center>
            <i>
              (
              {
                // ##### 23-10-04 Komarov
                two_eleven_years}
              )
            </i>
          </center>
        </h4>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "25% 17% 16% 17% 25%",
        }}
      >
        <h4>
          {
            // ##### 23-10-04 Komarov
            Rooms}
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <MinusOutlined
            className="Minus"
            style={{ color: "white" }}
            onClick={deductRooms} />
        </h4>
        <h4>
          <center>
            <b>{counterRooms}</b>
          </center>
        </h4>
        {/* ##### 24-02-08 Komarov */}
        <h4>
          <PlusOutlined
            className="Plus"
            style={{ color: "white" }}
            onClick={addRooms} />
        </h4>
      </div>
    </div>
  );
};
