// ##### 24-05-10 Komarov
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Media } from "../../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { Content } from "./Content";

// ##### 24-05-13 Komarov: Функция для получения блока для отображения месяца
export const MonthBlock = ({
  month,
  yearsMonths,
  markedDates,
  calendarHeader,
  // ##### 24-05-20 Komarov
  selectedDays,
}) => {
  const locale = useSelector((state) => state.language);
  // Отображаем количество строк, равное количеству недель, которые покрывает месяц
  // Количество столбцов в каждой строке - 7 - столбец на день недели
  // ##### 24-01-18 Komarov: Устанавливаем месяцем для отображения переданный в аргументе month (в виде числа от 0 до 11) или (в случае его отсутствия) - текущий месяц
  moment.locale(locale);
  const currentMonth = month;
  // console.log('currentMonth', currentMonth);
  // ##### 24-01-17 Komarov: Берём количество дней в месяце
  const daysInMonth = moment().month(currentMonth).daysInMonth();
  const firstDayOfMonthWeekDay = moment().month(currentMonth).date(1).weekday();
  // ##### 24-01-17 Komarov: Смотрим, сколько недель месяца покрывает месяц
  const weeksInMonth = Math.ceil((firstDayOfMonthWeekDay + daysInMonth) / 7);
  // console.log('weeksInMonth', weeksInMonth);
  // Узнаём номер дня недели первого числа текущего месяца
  const firstDayOfTheMonthWeekNumber =
    moment().month(currentMonth).date(1).weekday() + 1;
  // console.log('firstDayOfTheMonthWeekNumber', firstDayOfTheMonthWeekNumber);
  // Узнаём количество дней предыдущего месяца, которые попадают в начало первой недели текущего месяца
  const prevMonthDaysCount = firstDayOfTheMonthWeekNumber - 1;
  // console.log('prevMonthDaysCount', prevMonthDaysCount);
  // Узнаём количество дней следующего месяца по окончании текущего месяца, которое попадает на последнюю неделю текущего месяца
  const nextMonthDaysCount =
    weeksInMonth * 7 - (prevMonthDaysCount + daysInMonth);
  // console.log('nextMonthDaysCount', nextMonthDaysCount);
  // ##### 24-01-17 Komarov: Создаём массив объектов, каджый - с информацией о дате конкретного дня
  const dates = [];
  // Заполняем массив дат для блока текущего месяца
  // Начинаем цикл с 1 - номер_дня_недели_первого_числа (первый день недели - это 0) и продолжаем до количества дней в текущем месяце + количество дней следующего месяца, попадающих в последнюю неделю текущего месяца
  for (
    let i = 1 - firstDayOfTheMonthWeekNumber;
    i < daysInMonth + nextMonthDaysCount;
    i++
  ) {
    // Датой в каждой ячейке массива будет первое число текущего месяца плюс i
    const dayDate = moment().month(currentMonth).date(1).add(i, "days");
    dates.push(dayDate);
  }
  // console.log('dates', dates);
  // Если месяц текущего элемента массива не равен текущему, то отображаем его серым (светлее основного цвета), иначе - чёрным (основным цветом).
  // ##### 24-01-17 Komarov: Формируем массив дней для вывода в блок месяца
  const weeks = [];
  for (let i = 0; i < weeksInMonth; i++) {
    const arrayForWeek = [];
    for (let j = 0; j < 7; j++) {
      arrayForWeek.push(dates[i * 7 + j]);
    }
    weeks.push(arrayForWeek);
  }

  return (
    <>
      <Media lessThan="Tablet-768-1024">
        <Content props={{
          className: "calendar-row",
          currentMonth,
          weeks,
          yearsMonths,
          markedDates,
          calendarHeader,
          selectedDays
        }} />
      </Media>
      <Media at="Tablet-768-1024">
        <Content props={{
          className: "calendar-row-tablet",
          currentMonth,
          weeks,
          yearsMonths,
          markedDates,
          calendarHeader,
          selectedDays
        }} />
      </Media>
    </>
  );
};
