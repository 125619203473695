// ##### 25-02-12 Komarov
import React, { useContext } from "react";
import uniqueId from "lodash.uniqueid";
import { HotelRates } from "../HotelRates/HotelRates";
import { HotelContent } from "../HotelContent/HotelContent";
// ##### 25-02-12 Komarov
import { HotelItemsListContext } from "./HotelItemsList";

export const HotelWithDates = ({ hotelPrice, hotel_id }) => {
  const {
    liClassName
  } = useContext(HotelItemsListContext);

  return (
    //  ##### 23-02-22 Komarov
    <li
      key={uniqueId()}
      className={liClassName}
    >
      <HotelRates
        key={uniqueId()}
        hotelPrice={hotelPrice}
        hotel_id={hotel_id}
      />

      <HotelContent
        key={uniqueId()}
        hotel_id={hotel_id}
      />
    </li>
  );
};