// ##### 25-02-05 Komarov
import { PlaceHolderStrings } from "../../../../../Localization/placeholders";
import { useMessages } from "../../../../../../../helpers/messages";
import { useSelector } from "react-redux";
import uniqueId from "lodash.uniqueid";
import { Select } from "antd";
import { GuideTypes } from "../../../../../StaticJsonData/GuideTypes";
import React from "react";

export const GuideAssistantComponent = ({ props }) => {
  const {
    selectGuideTypes,
    guideTypes,
    guideTypesTypesInitialState
  } = props;
  const placeholder = PlaceHolderStrings();
  const chooseGuideLanguage = useMessages("ChooseGuideLanguage");
  const locale = useSelector((state) => state.language);

  return (<div className="groupTravelblocks_2" key={uniqueId()}>
    <span>
      {/* ##### 23-09-07 Komarov */}
      {chooseGuideLanguage}
    </span>
      <Select
        onChange={selectGuideTypes}
        bordered={true}
        style={{ minWidth: "5vw" }}
        // ##### 24-03-21 Komarov
        value={
          (guideTypes === guideTypesTypesInitialState &&
            placeholder.placeHolderSelect) ||
          guideTypes
        }
      >
        <>
          {
            // ##### 24-03-21 Komarov
            GuideTypes(locale)?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })
          }
        </>
      </Select>
    </div>
  );
};