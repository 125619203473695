// ##### 25-02-05 Komarov
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 23-03-29 Komarov
import { useLocation } from "react-router-dom";
// ##### 23-04-12 Komarov
// ##### 25-01-22 Komarov
import { getGeneralHotels, getHotelCities } from "../../../../Redux/actions";
// ##### 24-06-14 Komarov
import { ValidateQuery } from "../../Helpers/helper";
// ##### 23-09-26 Komarov
// ##### 24-04-23 Komarov
import { useMessages } from "../../../../helpers/messages";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { CityHotelsDiv } from "./CityHotelsDiv";

// ##### 23-09-14 Komarov
// import './CityHotelsCSS.css'

// ##### 25-02-05 Komarov
export const CityHotelsContext = createContext();

export const CityHotels = () => {
  // ##### 24-04-23 Komarov
  const See_Availability = useMessages("See Availability");
  // ##### 24-03-21 Komarov
  const location = useLocation();

  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // const [loaded,setLoaded] = useState(false)

  // ##### 25-01-15 Komarov
  // const search_details = ValidateQuery(location);
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const search_details = ValidateQuery(location, shouldCypherUrlParams);
  // console.log('LOCATION_SEARCH',search_details)

  const filteredLinkCityName = search_details.name
    .substring(0, search_details.name.indexOf("-Hotels".toLowerCase()))
    .replace(/\//g, "");
  // console.log('TESTPATH',filteredLinkCityName)

  const dispatch = useDispatch();

  const citiesList = useSelector((state) => state.hotelcities.hotel_cities);
  // ##### 24-03-21 Komarov
  // const deviceType = useSelector(state => state.deviceType);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/Footer/CityHotels.js:getGeneralHotels()"
      );
    dispatch(getGeneralHotels());
    //  ##### 23-04-12 Komarov
  }, [dispatch]);

  // console.log('HOTELLIST',general_smart_hotels)

  /* useEffect ( () => {
    dispatch (getHotelCities(locale))
  }) */

  // 22-08-22 Komarov (, [locale]) Добавил в массив зависимостей переменную locale, чтобы не было цикливания запросов
  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/PageComponents/Footer/CityHotels.js:getHotelCities(locale)"
      );
    dispatch(getHotelCities(locale));
    // ##### 23-04-12 Komarov
  }, [locale, dispatch]);
  // console.log('CITIES',citiesList)

  // ##### 23-04-10 Komarov: убрал отображение отеля, у которого нет перевода описания
  const filteredCities = citiesList.filter((hotel) => {
    return (
      hotel.eng_city_name
        .toLowerCase()
        .includes(filteredLinkCityName.toLowerCase()) === true &&
      hotel.localized_hotel_name.trim().length > 0
    );
  });

  // ##### 24-09-09 Komarov
  const cityHotelsContextValue = useMemo(() => ({
    filteredCities,
    citiesList,
    See_Availability,
    filteredLinkCityName,
  }), [
    // ##### 24-09-11 Komarov
    filteredCities,
    citiesList,
    See_Availability,
    filteredLinkCityName,
  ]);

  return (
    <CityHotelsContext.Provider
      value={cityHotelsContextValue}
    >
      {/* ##### 23-11-08 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <CityHotelsDiv
          className1="HotelFooterPages"
          className2="HotelContentWrapper"
        />
      </Media>
      {/* ##### 23-11-13 Komarov */}
      <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
        <CityHotelsDiv
          className1="HotelFooterPages"
          className2="HotelContentWrapperMediumScreen"
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <CityHotelsDiv
          className1="HotelFooterPagesSmallScreen"
          className2="HotelContentWrapperSmallScreen"
        />
      </Media>
    </CityHotelsContext.Provider>
  );
};
