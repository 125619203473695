// ##### 25-02-05 Komarov
import React, { createContext, useMemo } from "react";
import { ClientDetailsForm } from "./ClientDetailsForm";

export const ClientDetailsContext = createContext();

// ##### 24-09-11 Komarov
export const ClientDetails = ({ cart, formClassName }) => {
  const clientDetailsContextValue = useMemo(() => ({ cart }), [cart]); // value is cached by useMemo
  return (
    <ClientDetailsContext.Provider value={clientDetailsContextValue}>
      {/* ##### 23-11-08 Komarov */}
      <ClientDetailsForm className={formClassName} />
    </ClientDetailsContext.Provider>
  )
};
