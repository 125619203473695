// ##### 24-09-09 Komarov
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// ##### 24-04-12 Komarov
// ##### 25-01-22 Komarov
import { getContent, getHotelContent } from "../../../Redux/actions";
// ##### 23-02-22 Komarov
// ##### 24-04-23 Komarov
// ##### 24-04-12 Komarov
// ##### 25-02-05 Komarov
import { CartDetailsDiv } from "./CartDetailsDiv";

export const CartDetailsContext = createContext();

export const CartDetails = ({ cart, cartClass }) => {
  // console.log('CART', cart)
  const dispatch = useDispatch();
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);

  // @@@@@ 23-01-30 Komarov: Исправил ошибку определения того, какой массив currencies нужно взять
  // ##### 24-07-25 Komarov
  const currenciesFromState = useSelector((state) => state.currency.currencies);
  const currencies = currenciesFromState.length > 0 ? currenciesFromState : JSON.parse(localStorage.getItem("currency")).currencies;

  let exchangeRate;

  // ##### 24-03-21 Komarov
  for (const curr of currencies) {
    if (curr.name === cart.selected_currency)
      exchangeRate = parseInt(curr.value);
  }
  // ##### 24-09-16 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  const { user_id } = currentUser;

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/BookingForm/CartDetails.js:getHotelContent(cart.hotel_id,locale)"
      );
      // ##### 24-09-16 Komarov
    dispatch(getHotelContent(cart.hotel_id, locale, user_id));
    // ##### 23-04-12 Komarov
  }, [cart.hotel_id, locale, dispatch]);

  useEffect(() => {
    // ##### 24-03-21 Komarov
    process.env.NODE_ENV === "development" &&
      console.log(
        "axios:src/Components/Pages/BookingForm/CartDetails.js:getContent(cart.contract_id,locale)"
      );
    dispatch(getContent(cart.contract_id, locale));
    // ##### 23-04-12 Komarov
  }, [cart.contract_id, locale, dispatch]);

  // ##### 25-01-22 Komarov
  const cartDetailsContextValue = useMemo(() => ({ cart, cartClass, exchangeRate }), [cart]); // value is cached by useMemo

  return (
    <CartDetailsContext.Provider value={cartDetailsContextValue}>
      {/* ##### 23-11-09 Komarov */}
      <CartDetailsDiv className={cartClass} />
    </CartDetailsContext.Provider>
  );
};
