// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const ClickableLayer_02_ToursAutocompleteSmallScreenDivTag = ({
  width,
  height
}) => {
  const { onRangePickerDivClick, tourDate, placeholder } = useContext(
    ToursAutocompleteSmallScreenContext
  );
  return (
    <div
      data-component="ClickableLayer_02_ToursAutocompleteSmallScreenDivTag"
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width: width,
        backgroundColor: "white",
        border: "#196fbb",
        borderStyle: "solid",
        borderRadius: "1rem",
        height: height,
        fontFamily: "Arial",
        alignItems: "center",
        // ##### 23-04-26 Komarov
        margin: "0.3vw"
      }}
      id="clickableLayer_02_ToursAutocompleteSmallScreen"
      onClick={(e) => onRangePickerDivClick(e)}
      // ##### 24-03-21 Komarov
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onRangePickerDivClick(e);
        }
      }}
    >
      {/* ##### 23-03-30 Komarov */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          marginTop: "0.25em"
        }}
      >
        {tourDate === "" ? placeholder.placeHolderMonth : tourDate}
      </div>
    </div>
  );
};