// ##### 25-02-05 Komarov
import { useMessages } from "../../../../../helpers/messages";
import React from "react";

export const SearchResultsHeader = () => {
  const SearchResults = useMessages("SearchResults");
  return <div data-component="SearchResultsHeader">
    <h3 className="SearchResults">
      {
        // ##### 23-10-04 Komarov
        SearchResults
      }
    </h3>
  </div>;
};