// ##### 25-02-05 Komarov
import parse from "html-react-parser";
import { useMessages } from "../../../helpers/messages";
import React from "react";

export const NoSearchResultMessageEnd = ({ noTextMessage }) => {
  // ##### 24-09-23 Komarov
  const noSearchResultMessageEnd = parse(useMessages("NoSearchResultMessageEnd"));
  return (
    <h3 className="LoadingText" data-component="NoSearchResultMessageEnd">
      {/* ##### 25-01-28 Komarov */}
      {noTextMessage ? null : noSearchResultMessageEnd}
    </h3>
  );
};