// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { Media } from "../../../../helpers/MediaConfig";
import { CurrencySelectionToolContext } from "./CurrencySelectionTool";

export const OptionContent = ({ item }) => {
  const { deviceType } = useContext(CurrencySelectionToolContext);
  // ##### 23-10-31 Komarov
  return (
    <>
      <Media lessThan="Laptop-1024-1440">
        {
          // ##### 24-03-21 Komarov
          deviceType === "tablet" ? (
            <span style={{ fontSize: "150%", lineHeight: "175%" }}>
              {item.name}
            </span>
          ) : (
            item.name
          )
        }
      </Media>
      <Media greaterThanOrEqual="Laptop-1024-1440">{item.name}</Media>
    </>
  );
};