// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { SeoLogoIco } from "../../../Library/SEO/SeoLogoIco";
import { SeoMetaTitle } from "../../../Library/SEO/SeoMetaTitle";
import { SeoMetaKeywords } from "../../../Library/SEO/SeoMetaKeywords";
import { SeoMetaDescription } from "../../../Library/SEO/SeoMetaDescription";
import { HeaderClassDiv } from "./HeaderClassDiv";
import { Search } from "../../FirstPageofSearchModule/SearchResizersAndSwitchers/SearchFront";
import { RouteSwitcher } from "../../RoutesAndLinks/RouteSwitcher";
import { HeaderWhiteLabelContext } from "./HeaderWhiteLabel";

export const HeaderWrapperMain = () => {
  const {
    // ##### 24-03-21 Komarov
    // toggler_type,
    // body_messages,
    // ##### 24-03-01 Komarov
    isMainPage
  } = useContext(HeaderWhiteLabelContext);
  // ##### 23-09-21 Komarov
  return (
    <header
      data-component="HeaderWrapperMain"
      className="wrapperMain"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <SeoLogoIco />
      <SeoMetaTitle />
      <SeoMetaKeywords />
      <SeoMetaDescription />
      <HeaderClassDiv headerClass="WhiteLabelTopMenu" />
      {/* ##### 24-03-01 Komarov */}
      {isMainPage && <Search style={{ order: "2" }} />}
      {/* ##### 24-03-01 Komarov */}
      <RouteSwitcher />
    </header>
  );
};