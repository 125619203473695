// ##### 25-02-12 Komarov
import uniqueId from "lodash.uniqueid";
import React, { createContext } from "react";
import { useSelector } from "react-redux";
// ##### 25-02-05 Komarov
import { HotelWithDates } from "./HotelWithDates";
import { HotelWithoutDates } from "./HotelWithoutDates";
// ##### 25-02-12 Komarov
import { LoadingMessage } from "../../../../Library/PageDevices/LoadingMessage";

// ##### 25-02-05 Komarov
export const HotelItemsListContext = createContext();

// ##### 24-04-02 Komarov: Отображение перечня отелей города или одного отеля, если в Autocomplete был выбран отель
// ##### 25-02-12 Komarov
export const HotelItemsList = ({ ulCLassName, width, liClassName }) => {
  const hotels_avail_loaded = useSelector(state => state.hotels.hotels_avail_loaded);
  const hotels = useSelector(state => state.hotels.hotels_avail.data.hotels);
  const hotelcontent2Hotels = useSelector(state => state.hotelcontent.hotelcontent2.data.hotels);

  const showLoader = !hotels_avail_loaded;

  // ##### 24-04-05 Komarov
  return (
    <ul
      data-component="HotelItemsList"
      className={ulCLassName}
      style={{
        width: `${width}px`,
      }}
    >
      <HotelItemsListContext.Provider value={{
        liClassName,
      }}>
        {/* ##### 25-02-12 Komarov */}
        {
          showLoader
            ?
            (
              <div>
                <LoadingMessage
                  loadingMessageClass="RateLoading"
                />
              </div>
            )
            :
            Object.keys(hotels).map((hotel_id) => {
              // ##### 25-02-08 Komarov: Если в hotel значение notdispl установлено в отличное от 0 число, то не выводим этот отель
              if (!!parseInt(hotels[hotel_id].notdispl)) {
                return;
              }
              // TODO: Разный набор отелей в action'ах GetHotelAvailAlphaRQ и GetHotelContentAlphaRQ, что приводит к ошибкам на сайте.
              // Поэтому добавил фильтр, который отсекает (от показа из отелей, пришедших из Смарта по запросу на action "GetHotelAvailAlphaRS") отели, которые не содержатся в ответе на запрос к Смарту на action "GetHotelContentAlphaRS"
              // ##### 25-02-08 Komarov: Если в hotel значение notavail установлено в 0, то выводим этот отель как доступный сейчас, иначе - как отель под запрос
              if (!(hotel_id in hotelcontent2Hotels)) {
                return;
              }
              if (!!!parseInt(hotels[hotel_id].notavail)) {
                return <HotelWithDates
                  key={uniqueId()}
                  hotelPrice={hotels[hotel_id].min_price}
                  hotel_id={hotel_id}
                />
              }
              return <HotelWithoutDates
                key={uniqueId()}
                hotelPrice={hotels[hotel_id].min_price}
                hotel_id={hotel_id}
              />
            })
        }
      </HotelItemsListContext.Provider>
    </ul>
  );
};
