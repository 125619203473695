// ##### 25-02-05 Komarov
import { useMessages } from "../../../../helpers/messages";
import { LocalizationNavLink } from "../../../Library/Localization/LocalizationNavLink";
import uniqueId from "lodash.uniqueid";
import parse from "html-react-parser";
import { Cooperation } from "./Cooperation";
import { Requests } from "./Requests";
import React from "react";

export const DesktopMenuTextItems = ({
  sitepage_type,
  isWhiteLabelSet,
  companyCipher
}) => {
  const pagesFromLocalStorageArray = Object.values(useMessages()).filter(page => parseInt(sitepage_type.sitepage_type_id) ===
    parseInt(page.sitepage_type_id) &&
    parseInt(sitepage_type.sitepage_type_id) !== 7 &&
    parseInt(page.id) !== 198);

  // ##### 24-06-21 Komarov
  const cooperation = pagesFromLocalStorageArray.find(page => page.name === "Cooperation");
  // ##### 24-08-07 Komarov
  const requests = pagesFromLocalStorageArray.find(page => page.name === "Requests");

  return pagesFromLocalStorageArray.length > 0
    ?
    <>
      {pagesFromLocalStorageArray.filter(page => !["Clients", "Suppliers", "Group Request", "Cooperation", "Requests"].includes(page.name)).map((page) => {
        return (
          // ##### 24-03-04 Komarov
          <LocalizationNavLink
            to={
              (isWhiteLabelSet &&
                `/ref${companyCipher}/sitepages/?name=${page.name}&id=${page.id}`) ||
              `/sitepages/?name=${page.name}&id=${page.id}`
            }
            key={uniqueId()}
          >
            {parse(page.title)}
          </LocalizationNavLink>
        );
      })}
      <Cooperation
        pages={pagesFromLocalStorageArray.filter(page => ["Clients", "Suppliers"].includes(page.name))}
        title={cooperation.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
      />
      {/* ##### 24-08-07 Komarov */}
      <Requests
        pages={pagesFromLocalStorageArray.filter(page => ["Group Request"].includes(page.name))}
        title={requests.title}
        isWhiteLabelSet={isWhiteLabelSet}
        companyCipher={companyCipher}
      />
      {/* <LocalizationNavLink
        to={
          (isWhiteLabelSet &&
            `/ref${companyCipher}/sitepages/?name=${cooperation.name}&id=${cooperation.id}`) ||
          `/sitepages/?name=${cooperation.name}&id=${cooperation.id}`
        }
        key={uniqueId()}
      >
        {parse(cooperation.title)}
      </LocalizationNavLink> */}
    </>
    : React.Fragment;
};