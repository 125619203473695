// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { SmallScreenSearch } from "./SmallScreenSearch";
import { SearchContext } from "./SearchFront";

export const SmallScreenSearchTag = ({ wrapper, datepickerClass }) => {
  const { searchProps } = useContext(SearchContext);
  return (
    <SmallScreenSearch
      //  ##### 22-11-14 Komarov
      wrapper={wrapper}
      innerWrapper="formInnerWrapper_inner"
      formClass="mySearch_inner"
      autocompleteClass={null}
      datepickerClass={datepickerClass}
      props={searchProps}
    />
  );
};