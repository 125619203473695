// ##### 25-02-05 Komarov
import { Alert, Select } from "antd";
import { markRequired } from "../../../../../helpers/markRequired";
import LodgingTypes from "../../../StaticJsonData/LodgingTypes.json";
import React from "react";

export function PositionInput(props) {
  const { Option } = Select;
  return <>
    <span>
      {
        markRequired(props.labelText)
      }
    </span>
    <Select
      onChange={props.onChange}
      bordered={true}
      placeholder={props.placeholder}
      // ##### 23-10-02 Komarov: если поле не заполнено, оно обводится красной полоской
      status={!props.isPositionSet && "error"}
      style={{ width: "-webkit-fill-available" }}
    >
      <>
        {
          // ##### 24-03-21 Komarov
          LodgingTypes?.map((item) => {
            if (item.lang === props.locale) {
              return item.ownership_types.map((item1) => {
                return (
                  <Option value={item1} key={item1}>
                    {item1}
                  </Option>
                );
              });
            }
          })
        }
      </>
    </Select>
    {/* ##### 23-10-02 Komarov: оповещение пользователя о необходимости заполнить это поле */}
    {!props.isPositionSet && (
      <Alert
        message={props.labelText}
        type="error"
        showIcon
        closable
        onClose={props.onClose}
      />
    )}
  </>;
}