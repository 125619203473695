// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const ClickableLayer_01_ToursAutocompleteSmallScreenDivTag = ({
  width,
  height
}) => {
  const { onAutocompleteDivClick, tourValue, placeholder } = useContext(
    ToursAutocompleteSmallScreenContext
  );
  return (
    <div
      data-component="ClickableLayer_01_ToursAutocompleteSmallScreenDivTag"
      onClick={(e) => onAutocompleteDivClick(e)}
      // ##### 24-03-21 Komarov
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onAutocompleteDivClick(e);
        }
      }}
      id="clickableLayer_01_ToursAutocompleteSmallScreen"
      style={{
        gridColumn: "1",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-around",
        width: width,
        height: height,
        border: "#196fbb",
        borderStyle: "solid",
        borderRadius: "1rem",
        fontFamily: "Arial",
        alignItems: "center",
        // ##### 23-04-26 Komarov
        margin: "0.3vw"
      }}
    >
      {/* ##### 23-03-30 Komarov */}
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          marginTop: "0.25em"
        }}
      >
        {/* ##### 24-03-21 Komarov */}
        {tourValue === "" ? placeholder.placeHolderTour : tourValue}
      </div>
    </div>
  );
};