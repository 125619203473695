// ##### 24-07-24 Komarov
import { SET_COUNTRY } from "../constants";
export const setCountry = (country) => async (dispatch) => {
  process.env.NODE_ENV === "development" &&
    console.log("src/Redux/actions/setCountry.js:language", country);
  dispatch({
    type: SET_COUNTRY,
    payload: country,
  });
};
