// ##### 25-02-05 Komarov
import { MenuClose } from "../../../Library/Icons/menuclose";
import { MenuBar } from "../../../Library/Icons/menubar";
import React from "react";

export const SmallScreensNavBarButton = ({ className, toggle, translate }) => {
  // ##### 24-03-21 Komarov
  return (
    <button
      type="button"
      className={className}
      onClick={toggle}
      data-component="SmallScreensNavBarButton"
    >
      {" "}
      {translate ? (
        <span className="CloseMenuIcon">
          <MenuClose />
        </span>
      ) : (
        <span className="MenuButtonIcon">
          <MenuBar />
        </span>
      )}
    </button>
  );
};