// ##### 25-02-05 Komarov
// ##### 23-10-10 Komarov: TODO: параметры функции не передаются сюда, так есть ли в них смысл?..
// ##### 24-05-21 Komarov
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Switcher } from "./Switcher";
import { Hotels } from "../../../Library/Icons/hotels";
import { LargeScreenSearchContext } from "./LargeScreenSearch";
import { SwitcherItem } from "./SwitcherItem";

export const SwitcherTag = () => {
  const {
    toggler,
    align,
    hotels
    // ##### 24-03-21 Komarov
    // tours
  } = useContext(LargeScreenSearchContext);
  // ##### 24-05-10 Komarov
  const deviceType = useSelector((state) => state.deviceType);
  // ##### 24-05-21 Komarov
  return (
    <Switcher name={"align"} changeHandler={toggler} active={align}>
      {/* ##### 23-04-01 Komarov */}
      <SwitcherItem value="HOTELS">
        {Hotels(deviceType)}
        <h4>
          {
            // ##### 23-10-04 Komarov
            hotels
          }
        </h4>
      </SwitcherItem>
      {/* ##### 24-01-12 Komarov */}
      {/* <SwitcherItem value='TOURS'><Tours deviceType={deviceType} /><h4>
      {
        // ##### 23-10-04 Komarov
        tours
      }
    </h4>
    </SwitcherItem> */}
    </Switcher>
  );
};