import uniqueId from "lodash.uniqueid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "../../Library/PageDevices/Helmet";
import { HomePage } from "../PageComponents/HomePage/HomePage";
// ##### 25-02-05 Komarov
import { MenuPageGenerator } from "../PageComponents/MenuPageGenerator/MenuPageGenerator";
import { NotFound } from "../PageComponents/MenuPageGenerator/NotFound";
// ##### 24-06-14 Komarov
import { HotelItems } from "../SecondPageofSearchModule/Hotels/HotelItems/HotelItems";
import { GuestItem } from "../SecondPageofSearchModule/Tours/GuestItem/GuestItem";
// ##### 24-06-14 Komarov
import Register from "../../Library/Authorization/Register";
import { BookingForm } from "../BookingForm/BookingForm";
import { OfflineSummary } from "../BookingForm/OfflineSummary";
import PaymentResult from "../LiqPay/PaymentResult";
import { CityHotels } from "../PageComponents/Footer/CityHotels";
import { TopToursDetails } from "../PageComponents/HomePage/TopToursDetails";
import { HotelDetails } from "../ThirdPageofSearchModule/Hotels/HotelDetails";
import { TourDetails } from "../ThirdPageofSearchModule/Tours/TourDetails/TourDetails";
// ##### 25-01-22 Komarov
import { changeLang } from "../../../Redux/actions";
import { LocalizationRoute } from "../../Library/Localization/LocalizationRoute";
import { LocalizationSwitch } from "../../Library/Localization/LocalizationSwitch";
import { CanxBooking } from "../ChangeBooking/CanxBooking";
import { RegistrationRotary } from "../CustomizedPages/RegistrationRotary";
import { ContentPages } from "../PageComponents/ContentPages";
// ##### 25-02-05 Komarov
import { FORGROUPS } from "../PageComponents/TopMenuComponents/FORGROUPS";

export const RouteSwitcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLang());
    // ##### 23-04-12 Komarov
  }, [dispatch]);

  // ##### 24-07-25 Komarov
  const lang = useSelector((state) => state.language);
  const pagesFromState = useSelector((state) => state.pages.pages);
  // ##### 24-07-25 Komarov
  const pages =
    (pagesFromState.length > 0 &&
      pagesFromState) ||
    ContentPages(lang);

  // ##### 24-03-11 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );

  // console.log('SITEPAGES',pages )

  // console.log('LOCALE', locale)
  return (
    <LocalizationSwitch>
      {/* <Switch> */}
      <LocalizationRoute
        path="/helmet"
        component={Helmet}
        key={() => uniqueId()}
      />
      {/* <LocalizationRoute exact path='/sign_in' component={Login} key={() => uniqueId()}/> */}
      <LocalizationRoute
        exact
        path="/sign_up"
        component={Register}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/payment_result"
        component={PaymentResult}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/payment_result`}
        component={PaymentResult}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/forgroups"
        component={FORGROUPS}
        key={() => uniqueId()}
      />
      {/* /* <Route component={NotFound} /> */}
      <LocalizationRoute
        exact
        path="/"
        component={HomePage}
        key={() => uniqueId()}
      />
      {/* <Route exact path='/hotels_in_ukraine' component={SingleHotel}/> */}
      {/* <Route exact path='/testcities' component={TestCities} />  */}
      {/* <LocalizationRoute path='/testcities' component={TestCities} /> */}
      {/* <Route exact path='/search_results' component={GuestItem}/>  */}
      <LocalizationRoute
        exact
        path="/search_results_tours"
        component={GuestItem}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/search_results_tours`}
        component={GuestItem}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/search_results_hotels"
        component={HotelItems}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/search_results_hotels`}
        component={HotelItems}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/hotel_details"
        component={HotelDetails}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/hotel_details`}
        component={HotelDetails}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/tour_details"
        component={TourDetails}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/tour_details`}
        component={TourDetails}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/booking_form"
        component={BookingForm}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/booking_form`}
        component={BookingForm}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/toptours"
        component={TopToursDetails}
        key={() => uniqueId()}
      />
      {/* <LocalizationRoute exact path='/tophotels' component={TopHotelsDetails} key={() => uniqueId()}/> */}
      <LocalizationRoute
        exact
        path="/offlineSummary"
        component={OfflineSummary}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/offlineSummary`}
        component={OfflineSummary}
        key={() => uniqueId()}
      />
      <LocalizationRoute exact path="/test" component={NotFound} />
      <LocalizationRoute
        exact
        path="/rotaryform"
        component={RegistrationRotary}
        key={() => uniqueId()}
      />
      <LocalizationRoute
        exact
        path="/mybooking"
        component={CanxBooking}
        key={() => uniqueId()}
      />
      {/* ##### 24-08-23 Komarov */}
      <LocalizationRoute
        exact
        path={"/hotel_overview"}
        component={MenuPageGenerator}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-21 Komarov */}
      {/* ##### 24-07-25 Komarov: TODO: убрать из кода фиксированное значение Promocode (refpartner), а, например, при вводе Промокода проверять наличие такого значения Промокода в Смарте, и в случае наличия присылать соотв. картинки и др. данные для страницы этой компании */}
      {/* ##### 24-08-05 Komarov */}
      {/* TODO: Исправить код в соответствии с задачей убрать Промокод из URL */}
      {/* <LocalizationRoute
        path="/refpartner"
        component={
          (search_data.refpartner === "rot654321" && HeaderWhiteLabel) ||
          HomePage
        }
        key={() => uniqueId()}
      /> */}
      {/* ##### 24-03-11 Komarov */}
      <LocalizationRoute
        exact
        path={`/ref${company_cipher}/sitepages`}
        component={MenuPageGenerator}
        key={() => uniqueId()}
      />
      {/* ##### 24-03-01 Komarov */}

      {
        // ##### 23-09-14 Komarov
        //   pages&&pages.map((page)=>{
        // ##### 24-03-21 Komarov
        Object.values(pages)?.map((page) => {
          if (page.name.includes("Hotels") === true) {
            // ##### 24-03-21 Komarov
            return (
              <LocalizationRoute
                exact
                path={"/sitepages_hotels"}
                component={CityHotels}
                key={() => uniqueId()}
              />
            );
          }
          if (parseInt(page.id) === 198) {
            // ##### 24-03-21 Komarov
            return (
              <LocalizationRoute
                exact
                path={"/profile"}
                component={MenuPageGenerator}
                key={() => uniqueId()}
              />
            );
          }
          // ##### 24-03-21 Komarov
          if (
            page.sitepage_type_name &&
            ["ATTRACTIONS"/* , "CITIES" */].indexOf(page.sitepage_type_name) > -1
          ) {
            // ##### 24-03-21 Komarov
            return (
              <LocalizationRoute
                exact
                path={"/city_overview"}
                component={MenuPageGenerator}
                key={() => uniqueId()}
              />
            );
          }
          return (
            // ##### 24-03-21 Komarov
            <LocalizationRoute
              exact
              path={"/sitepages"}
              component={MenuPageGenerator}
              key={() => uniqueId()}
            />
          );
        })
      }
    </LocalizationSwitch>
  );
};
