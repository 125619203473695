// ##### 25-02-05 Komarov
// ##### 24-05-22 Komarov: компонент с элементом для отображения условных обозначений точек на иконках в элементах Autocomplete
import { useMessages } from "../../../../../helpers/messages";
import { AvailableNowSign } from "../../../../Library/Icons/availableNowSign";
import { OnRequestSign } from "../../../../Library/Icons/onRequestSign";
import React from "react";

export const HotelsCitiesAvailabilityHint = () => {
  const availableNow = useMessages("Available_Now");
  const onRequest = useMessages("Available_OnRequest");
  // const space = parse("&nbsp;");
  return (
    <>
      <div style={{ display: "flex" }}>
        <AvailableNowSign /> &nbsp; {availableNow}
      </div>
      <div style={{ display: "flex" }}>
        <OnRequestSign /> &nbsp; {onRequest}
      </div>
    </>
  );
};