// ##### 23-12-27 Komarov
import React from "react";
import { LiqPayPay } from "react-liqpay";
import { useSelector } from "react-redux";
// ##### 24-04-23 Komarov
import { useMessages } from "../../../helpers/messages";
// ##### 23-11-15 Komarov
import { managersEmails } from "../../../configs/managers";
// ##### 24-12-23 Komarov
import { encrypt } from "../../../helpers/crypto";
// ##### 25-02-05 Komarov
import { ButtonComponent } from "./ButtonComponent";

// ##### 24-03-21 Komarov
export const Pay = ({
  smart_service_id,
  smart_order_id,
  smart_reference,
  cart,
  client /* , service_number */,
}) => {
  //ВОТ ДОБАВИЛА ИНФО О ЛОКАЛИ . можешь включить параметр locale в запрос ниже
  // ##### 23-12-27 Komarov
  const locale = useSelector((state) => state.language);
  // ##### 23-09-26 Komarov
  const { user: currentUser } = useSelector((state) => state.auth);
  // ##### 24-03-01 Komarov
  const company_cipher = useSelector(
    (state) => state.whitelabel.company_cipher
  );
  // ##### 24-08-05 Komarov
  const company_id = useSelector(state => state.auth.user.company_id);
  // ##### 24-03-14 Komarov
  const isWhiteLabelDemo = useSelector((state) => state.whitelabel.isDemo);
  // ##### 24-12-23 Komarov
  // const CryptoJS = require("crypto-js");

  // TODO: Взять откуда-то site_order_id и site_service_id
  const orderData = {
    username: process.env.REACT_APP_SMART_USERNAME,
    password: process.env.REACT_APP_SMART_PASSWORD,
    // "user_id" :1426,
    user_id: currentUser.user_id,
    // ##### 24-08-05 Komarov
    refpartner: company_id,
    language: locale,
    // ##### 23-02-20 Komarov
    // "action":"GetPaymentInfoRQ",
    action: "BookingFromCartRQ",
    data: {
      // ##### 25-02-12 Komarov
      company_cipher: company_cipher || '',
      site_order_id: 1, // index of the order in the site (by default = 1) - в БД нет
      // ##### 24-08-16 Komarov
      smart_order_id,
      smart_reference, // twid_reference in Smart - for control
      // TODO: Добавить реальный smart_order_id
      site_service_id: 1, // index of the service in the site
      smart_service_id, // booking_entity.id in Smart
      // ##### 23-12-22 Komarov
      site_client_id: 1,
      smart_client_id: currentUser.user_id,
      // "smart_service_number": service_number,		// На будущее, когда в заказе будет разрешено несколько сервисов - booking_entity.ref_serv_smart
    },
  };

  // ##### 24-12-23 Komarov
  /*const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(orderData),
    process.env.REACT_APP_PRIVATE_KEY_PROD
  ).toString();
  localStorage.setItem("orderData", ciphertext);*/
  localStorage.setItem("orderData", encrypt(orderData));
  // ##### 23-02-20 Komarov
  localStorage.setItem("smart_service_id", smart_service_id);
  localStorage.setItem("smart_reference", smart_reference);

  const payInfo = {
    // amount: 1,
    // currency: 'UAH',
    // title: 'PAY'
    // ##### 24-08-16 Komarov
    client,
    start: cart.start,
    service: cart.htlName.replace("%20", " "),
    adults: cart.adults,
    children: cart.children,
    amount: cart.amount,
    units: cart.numberofunits,
    // ##### 24-08-16 Komarov
    currency:
      (localStorage.getItem("current_currency")) ||
      "UAH",
    // ##### 23-11-15 Komarov
    delivery_emails: managersEmails,
  };
  // ##### 24-04-23 Komarov
  const PaymentForAccommodation = useMessages("PaymentForAccommodation");

  // Описание платежа, которое выводится на странице ввода деталей платёжной карты
  // const paymentDescription = () => `title: ${payInfo.title}
  // amount: ${payInfo.amount}
  // currency: ${payInfo.currency}`;

  const paymentDescription = () =>
    `client: ${payInfo.client}
    start: ${payInfo.start}
    service: ${payInfo.service}
    adults: ${payInfo.adults}
    children: ${payInfo.children}
    amount: ${payInfo.amount}
    units:${payInfo.units}
    currency: ${payInfo.currency}`;

  return (
    <div style={{ display: "flex" }} data-component="Pay">
      <LiqPayPay
        // ##### 24-03-14 Komarov
        // publicKey={process.env.REACT_APP_PUBLIC_KEY_PROD}
        // privateKey={process.env.REACT_APP_PRIVATE_KEY_PROD}
        publicKey={
          (isWhiteLabelDemo && process.env.REACT_APP_PUBLIC_KEY_TEST) ||
          process.env.REACT_APP_PUBLIC_KEY_PROD
        }
        privateKey={
          (isWhiteLabelDemo && process.env.REACT_APP_PRIVATE_KEY_TEST) ||
          process.env.REACT_APP_PRIVATE_KEY_PROD
        }
        // TODO: create Component to load description dynamically
        description={paymentDescription()}
        // TODO: send booking_entity_id
        // ##### 23-02-20 Komarov
        orderId={smart_reference}
        // ##### 24-03-01 Komarov
        // result_url={`${process.env.REACT_APP_URL}/${locale}/payment_result`}
        result_url={
          (company_cipher &&
            `${process.env.REACT_APP_URL}/${locale}/ref${company_cipher}/payment_result`) ||
          `${process.env.REACT_APP_URL}/${locale}/payment_result`
        }
        server_url={process.env.REACT_APP_SERVER_URL}
        // ##### 24-01-31 Komarov
        product_description={PaymentForAccommodation}
        style={{ margin: "8px" }}
        extra={[<ButtonComponent key="1" />]}
        amount={payInfo.amount}
        currency={payInfo.currency}
        // ##### 23-11-15 Komarov
        delivery_emails={payInfo.delivery_emails}
        language={locale}
      />
    </div>
  );
};
