// ##### 25-02-05 Komarov
import React from "react";
// ##### 23-11-08 Komarov
import { Media } from "../../../../helpers/MediaConfig";
// ##### 24-11-29 Komarov
import { CartGalleryDiv } from "./CartGalleryDiv";

// ##### 23-11-08 Komarov
// import './CartGalleryCSS.css'

// ##### 23-10-10 Komarov
export const CartGallery = ({ photos, smallImageHeight }) => (
  <>
    {/* ##### 23-11-08 Komarov */}
    <Media lessThan="Laptop-1024-1440">
      <CartGalleryDiv
        height="unset"
        flexDirection="column"
        width1="100%"
        width2="100%"
        photos={photos}
        smallImageHeight={smallImageHeight}
      />
    </Media>
    <Media greaterThanOrEqual="Laptop-1024-1440">
      <CartGalleryDiv
        height="55vh"
        flexDirection="row"
        width1="60%"
        width2="40%"
        photos={photos}
        smallImageHeight={smallImageHeight}
      />
    </Media>
  </>
);
