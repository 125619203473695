// ##### 25-02-05 Komarov
import React, { useContext } from "react";
import { useMessages } from "../../../../helpers/messages";
import { PromoCode } from "../HotelsAutocompleteBlock/PromoCode";
import { ToursAutocompleteSmallScreenContext } from "./ToursAutocompleteSmallScreen";

export const BorderInnerWrapper2DivTag = ({ width = "unset" }) => {
  const {
    // States variables and functions
    addToList
  } = useContext(ToursAutocompleteSmallScreenContext);

  const Search = useMessages("Search");

  return (
    <div
      data-component="BorderInnerWrapper2DivTag"
      // ##### 23-11-02 Komarov
      className="borderInnerWrapper2"
    >
      <div
        style={{
          display: "flex",
          width
        }}
      >
        <div className="promocode_mobile">
          {/* ##### 24-08-05 Komarov */}
          {/* TODO: Исправить код с учтётом различия понятий refpartner и promoCode */}
          <PromoCode /* refpartner={searchProps ? searchProps.refpartner : ""} */ />
        </div>

        <button type="submit" onClick={addToList}>
          {
            // ##### 23-10-04 Komarov
            Search
          }
        </button>
      </div>
    </div>
  );
};