// ##### 25-02-12 Komarov
import React from "react";
import { useMessages } from "../../../../../helpers/messages";
import { HotelBookButton } from "../../../PageComponents/HotelBookButton/HotelBookButton";
// ##### 25-02-12 Komarov
import { useSelector } from "react-redux";
import moment from "moment";
import { ValidateQuery } from "../../../Helpers/helper";

// ##### 25-02-12 Komarov
export const HotelRatesContent = ({ gridAutoRows, hotelPrice, hotel_id }) => {
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));
  const search_data = ValidateQuery(location, shouldCypherUrlParams);
  const date_difference = moment(search_data.end).diff(
    moment(search_data.start),
    "days"
  );
  const adults = search_data.adults;
  const pickedCurrency = search_data.selected_currency;

  // ##### 24-05-10 Komarov
  const Adults = useMessages("Adults");
  const TotalCost = useMessages("TotalCost");
  const Days = useMessages("Days");
  const Inclusions_1 = useMessages("Inclusions_1");
  const See_Availability = useMessages("See Availability");
  const ViewMore = useMessages("ViewMore");

  // ##### 24-04-23 Komarov
  const from = useMessages("From");
  // ##### 25-02-12 Komarov
  const hotel_avail = !!!parseInt(useSelector(state => state.hotels.hotels_avail.data.hotels[hotel_id].notavail));
  const hotelName = useSelector(state => state.hotels.hotels_avail.data.hotels[hotel_id].hotel_name);

  return (
    <div
      data-component="HotelRatesContent"
      style={{
        display: "grid",
        // ##### 23-07-05 Komarov
        // gridTemplateColumns: '50% 50%',
        gridTemplateColumns: "20% 80%",
        gridAutoRows,
      }}
    >
      {/* ##### 25-01-28 Komarov: отображаем id отеля только в режиме разработки */}
      {process.env.NODE_ENV === "development" && <div className="hotel-id">
        {hotel_id}
      </div>}
      {/* ##### 23-07-05 Komarov */}
      <h3
        style={{
          gridColumn: "2",
          gridRow: "2",
          textAlign: "end",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            // fontSize: "12px",
            fontSize: "14px",
            color: "grey",
            fontWeight: "bold",
            marginBottom: "1vh",
          }}
        >
          {
            // ##### 25-02-12 Komarov
            hotel_avail && TotalCost
          }
          <span style={{ marginLeft: "5px" }} />
          {date_difference}
          <span style={{ marginRight: "5px" }} />
          {
            // ##### 23-10-04 Komarov
            Days}
          , {adults} <span style={{ marginRight: "5px" }} />
          {
            // ##### 23-10-04 Komarov
            Adults}
        </div>

        {/* ##### 24-04-03 Komarov: Показываем цену только для отелей, доступных сейчас */}
        {
          // ##### 25-02-12 Komarov
          hotel_avail
          &&
          (
            <div
              style={{
                fontSize: "14px",
                color: "blue",
                fontWeight: "bold",
              }}
            >
              {/* ##### 24-02-22 Komarov */}
              {from} {pickedCurrency}{" "}
              {hotelPrice}
            </div>
          )
        }
      </h3>
      {/* ##### 24-04-05 Komarov */}
      {
        hotel_avail
        &&
        (
          <h4
            style={{
              // fontSize: "12px",
              fontSize: "14px",
              color: "grey",
              fontWeight: "bold",
              gridColumn: "2",
              gridRow: "3",
              textAlign: "end",
            }}
          >
            {
              // ##### 23-10-04 Komarov
              Inclusions_1}
          </h4>
        )
      }
      {/* ##### 23-07-05 Komarov */}
      <div
        style={{
          gridColumn: "2",
          gridRow: "4",
          textAlign: "end",
        }}
      >
        <HotelBookButton
          // ##### 24-11-14 Komarov
          innerText={
            // ##### 24-04-05 Komarov
            (
              hotel_avail
              &&
              See_Availability
            )
            ||
            ViewMore
          }
          // ##### 25-02-12 Komarov
          hotelName={decodeURIComponent(hotelName)}
          hotel_id={hotel_id}
        />
      </div>
    </div>
  );
};
