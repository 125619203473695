// ##### 25-02-05 Komarov
import { dateIsBeforeToday } from "../helpers/dateIsBeforeToday";
import { capitalize } from "../../../../../helpers/capitalize";
import moment from "moment/moment";
import uniqueId from "lodash.uniqueid";
import React from "react";
import { Media } from "../../../../../helpers/MediaConfig";
import { Day } from "./Day";

export const Content = ({ props }) => {
  const {
    className,
    currentMonth,
    weeks,
    yearsMonths,
    markedDates,
    calendarHeader,
    selectedDays
  } = props;

  // ##### 24-01-19 Komarov: функция для определения пронадлежности текущего дня текущему месяцу для изменения цвета дней не текущего месяца
  const notThisMonth = (day) => day.month() !== currentMonth % 12;

  const setClassesForMark = (day) => {
    return `align-self-center ${(notThisMonth(day) && "calendar-not-this-month") || ""
    } ${(dateIsBeforeToday(day) && "calendar-disabled-date") || ""}`;
  };

  return (
    <div
      data-component="MonthBlock"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <div
        style={{
          fontWeight: "bold"
          // marginBottom: '0.7em',
        }}
        // className='calendar-row align-items-center'
        className={`${className} align-items-center`}
      >
        {`${capitalize(
          moment().month(currentMonth).format("MMMM")
        )} ${moment().month(currentMonth).format("YYYY")}`}
      </div>
      {weeks.map((week) => {
        return (
          <div
            key={uniqueId()}
            // className='calendar-row'
            className={className}
          >
            {week.map((day) => {
              return (
                <React.Fragment key={uniqueId()}>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-lms ${setClassesForMark(day)}`}
                    lessThan="Mobile-S-320-375"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-ms ${setClassesForMark(day)}`}
                    at="Mobile-S-320-375"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-mm ${setClassesForMark(day)}`}
                    at="Mobile-M-375-425"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-ml ${setClassesForMark(day)}`}
                    at="Mobile-L-425-768"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                  <Media
                    key={uniqueId()}
                    className={`calendar-col-t ${setClassesForMark(day)}`}
                    at="Tablet-768-1024"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Day
                      day={day}
                      currentMonth={currentMonth}
                      yearsMonths={yearsMonths}
                      markedDates={markedDates}
                      calendarHeader={calendarHeader}
                      selectedDays={selectedDays}
                    />
                  </Media>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};