// ##### 24-09-09 Komarov
import React, { createContext, useMemo } from "react";
import { Media } from "../../../../helpers/MediaConfig";
// ##### 25-02-05 Komarov
import { SwitcherDiv } from "./SwitcherDiv";

// ##### 25-02-05 Komarov
export const SwitcherContext = createContext();

export const Switcher = (props) => {
  // ##### 24-03-21 Komarov
  const { children, active, changeHandler } = props;

  // ##### 24-09-09 Komarov
  const switcherContextValue = useMemo(() => ({
    children,
    active,
    changeHandler,
  }), [
    // ##### 24-09-11 Komarov
    children,
    active,
    changeHandler,
  ]);

  // ##### 23-03-01 Komarov
  return (
    <SwitcherContext.Provider
      value={switcherContextValue}
    >
      {/* ##### 23-10-31 Komarov */}
      <Media greaterThanOrEqual="Laptop-1024-1440">
        <SwitcherDiv
          className="switcher"
          // width: '16em'
          width="12em"
          // ##### 23-11-02 Komarov
          buttonClassName="switcher__item"
        />
      </Media>
      <Media lessThan="Tablet-768-1024">
        <SwitcherDiv
          className="switcher_mobile"
          width="100vw"
          buttonClassName="switcher__item_mobile"
        />
      </Media>
      <Media between={["Tablet-768-1024", "Laptop-1024-1440"]}>
        <SwitcherDiv
          className="switcher_mobile"
          width="100vw"
          buttonClassName="switcher__item_tablet_portrait"
        />
      </Media>
    </SwitcherContext.Provider>
  );
};

// ##### 25-02-05 Komarov
export const SwitcherItemContext = createContext();

