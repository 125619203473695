// ##### 25-02-05 Komarov
import { useMessages } from "../../../helpers/messages";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ValidateQuery } from "../../Pages/Helpers/helper";
import { NoSearchResultMessageStart } from "./NoSearchResultMessageStart";
import { NoSearchResultMessageEnd } from "./NoSearchResultMessageEnd";
import React from "react";

export const NoSearchResult = ({ noTextMessage }) => {
  const PleaseInputCityorHotelName = useMessages("PleaseInputCityorHotelName");
  const StartDate = useMessages("StartDate");
  const EndDate = useMessages("EndDate");
  const Adults = useMessages("Adults");
  const Children = useMessages("Children");
  const Rooms = useMessages("Rooms");
  const SelectedCurrency = useMessages("SelectedCurrency");
  const location = useLocation();
  const shouldCypherUrlParams = useSelector(state => !!parseInt(state.siteCypherCodeData.data.value));

  const search_data = ValidateQuery(location, shouldCypherUrlParams);

  return (
    <>
      <NoSearchResultMessageStart noTextMessage={noTextMessage} />
      <div>
        {PleaseInputCityorHotelName}: {search_data.title}
      </div>
      <div>
        {StartDate}: {search_data.start}
      </div>
      <div>
        {EndDate}: {search_data.end}
      </div>
      <div>
        {Adults}: {search_data.adults}
      </div>
      <div>
        {Children}: {search_data.children}
      </div>
      <div>
        {Rooms}: {search_data.rooms}
      </div>
      <div>
        {SelectedCurrency}: {search_data.selected_currency}
      </div>
      <NoSearchResultMessageEnd noTextMessage={noTextMessage} />
    </>
  );
};